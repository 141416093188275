import React from "react";
import DescriptionDTO from "../components/Exhibition/DescriptionDTO";
import {ExhibitionStatus, MarkerDTO} from "../components/MapCard/MarkerDTO";
import {
    WILD_PLANTS_PALESTINE_ARAB_METADATA,
    WILD_PLANTS_PALESTINE_ARAB_T1,
    WILD_PLANTS_PALESTINE_ARAB_T2,
    WILD_PLANTS_PALESTINE_ARAB_TITLE,
    ZERO_HISTOIRE_ARAB_GRIET_DOBBELS,
    ZERO_HISTOIRE_ARAB_METADATA,
    ZERO_HISTOIRE_ARAB_T1,
    ZERO_HISTOIRE_ARAB_T2,
    ZERO_HISTOIRE_ARAB_T3,
    ZERO_HISTOIRE_ARAB_T4,
    ZERO_HISTOIRE_ARAB_T5,
    ZERO_HISTOIRE_ARAB_TITLE
} from "./TheArabCultureAssociationArabicTexts";
import {ARABIC_TITLE} from "../Constants";
import {
    GLOSSOLALIA_METADATA,
    GLOSSOLALIA_P1,
    GLOSSOLALIA_P2,
    GLOSSOLALIA_P3,
    GLOSSOLALIA_P4,
    GLOSSOLALIA_P5,
    GLOSSOLALIA_P6
} from "./GlossolaliaArabicTexts";

const HOMEPAGE_LIST = {
    emergent: "Veurne: ESSA GRAYEB - GRIET DOBBELS : 01/11/2020 - 31/01/2021",
    almamal: "Old City Jerusalem: dates and artists coming soon",
    warp: "Sint-Niklaas: IGNACE CAMI - MANAL MAHAMID : 09/01/2021 - 21/02/2021",
    the_arab_culture_association: "Haifa: ALAA ABU ASAD - GRIET DOBBELS : 08/03/2021 - 20/04/2021",
    sakakini: "Khalil Sakakini CC, Ramallah: IGNACE CAMI - MILENA DESSE - MIRNA BAMIEH - SHADA SAFADI - SHURUQ HARB & MATS REHNMAN : 20/04/2021 - 20/05/2021",
    glossolalia: "Maastricht: ALAA ABU ASAD and IGNACE CAMI : 20/05/2021"
}

const DESCRIPTION_LIST = {
    emergent: [new DescriptionDTO(<p className="bold">Green Watchtower</p>, <p>2018, masking animation on video and
            sound played in loop,
            illustration by Yazan SitAbouha</p>,
        <p className="info">“The idea for this work came after my encounter with a two-story abandoned tower located on
            a hill between the Palestinian villages of “Ein Karem” and “Al-Jura” which were depopulated during the 1948
            Nakba. The tower, which was visible from the time it was built at the end of the 19th century until the
            Palestinian 1984 Nakba, was covered by the trees planted around it as part of the afforestation projects of
            the lands operated by the JNF (the Jewish National Fund). Those trees were recently cut as a result of a
            campaign of thinning and pruning the trees in the forest following an outbreak of fires in the area. The
            tower was then exposed and hidden again in the video work.”<br/>Essa Grayeb (°1984 Nazareth) is a
            Palestinian visual artist working in Jerusalem. He primarily works with photography, video and installation.
            Some of Essa’s works are made from objects, footage and sounds that already exist, which he appropriates and
            manipulates. In his works he touches upon notions of representation, absence and loss. His work often
            originates from an examination of his personal narratives within the collective history and memory, while
            often weaving in and out of reality and fiction.<br/>He lives and works in Jerusalem.
        </p>, "https://www.essagrayeb.com", "https://www.essagrayeb.com/greenwatchtower"), new DescriptionDTO(<p
            className="bold">Atlas Universel (04)</p>,
        <p>2020, drawing on historic map (published by Monin & Fremin, 1822-1834), beeswax, 24-carat gold leaf, 45 x
            31.5 cm</p>,
        <p className="info">How subjective is a map? How unilaterally is it measured and designed?<br/>Representations
            of the world are drawn by the most powerful rulers of the day and reflect the state of the world at a
            specific moment in time. History is not a straight line.<br/>This autumn, Griet Dobbels should have been
            embarking on two consecutive residencies in the Middle East. One at the Al Ma’Mal foundation in Jerusalem
            and the other at Birzeit University. Both have been postponed due to COVID.<br/>The ‘Atlas Universel’
            drawings represent Griet Dobbels’ endeavours to continue the research she had planned to undertake in the
            Middle East. Only instead of Jerusalem or the West Bank, she has been working in her studio ‘under the
            church tower’ in Nazareth. To make these works, Dobbels creates her own mediums (pastels, wax crayons, ink)
            from locally sourced materials (beeswax, walnut ink, soil, soot, herbs, spices, …), which she then applies
            to ancient maps from Imperial times. The local materials allude to a respect for origins, the local and the
            need for tactility.<br/>The ‘Atlas Universel’ drawings are an attempt to question perceptions in both
            directions rather than just from a Western point of view. At the same time, they also investigate how the
            landscape changes and the unique aspects that define a ‘place’ or ‘territory’. In these works, Dobbels
            explores the history of the landscape from various different perspectives – industrial, political,
            geological, geographical, demographical and archaeological – and how its usage changes over time.<br/>Griet
            Dobbels (b. 1964) is a Belgian conceptual artist working in Nazareth.<br/>Key concepts in her work are:
            perception, position, border areas, mapping, measuring and controlling and the dialectic between local and
            the global, transience and timelessness, nature and culture, beauty and horror, safety and danger, chaos and
            control, universality and individuality.</p>, "https://grietdobbels.be", "")
        ],
    almamal: [],
    warp: [
        new DescriptionDTO(<p className="bold">Exoten</p>, <p>2012, PU-foam / wood / sansevieria / wooden furniture, 180
            x 160 x 4"</p>, <p
            className="info">In a globalised world with more export of products and persons over a bigger distance,
            'exotics' migrate from their own natural habitat to another. ‘Exoten’ is a PU-foam sculpture of a stripped
            down lion with a sansevieria planted in its back. Its front paws rest on a little wooden table and its head
            is twisted 180°.<br/>Within Belgium, the region of Flanders and Flemings form a clearly distinguishable
            group set apart by their language and customs.The Flemish authorities use two logos of a highly stylized
            black lion which show the claws and tongue in either red or black. Cami highlights the ongoing dialogue
            between objects, symbols, memory and identity. The sansevieria, so familiar in Flemish interiors,
            historically is native to Africa. Thinking about infiltrants, migrants or aliens, do we rather think about
            their loss of homeland or about caused damage? Infiltration also inhabits an enriching impact and an open
            view on the world.<br/>Cami re-defines definitions of ‘home’, of being in the world, and constructs a
            transnational context. Displacement seems to be a major component of contemporary identity-making. It
            includes an inclusive approach out of global and cosmopolitan experiences and artifacts.<br/>The
            multi-disciplinary practice of Ignace Cami (°1986, BE) is deeply rooted in folklore and explores identity
            and tradition. He takes his heimat as a starting point for storytelling, sculptures, performative objects or
            installations.<br/>Trained as a printmaker and sculptor, his early works were mostly sculptures, drawings
            and videos testing the contemporary meaning of local history. During the time his practice started changing.
            <br/>From autonomous and static artworks he started moving towards more active and social ways of
            distributing his works and also physically being a part of them. Gradually he started allowing fictionalized
            personal history and memories into his practice, making it more relatable. With these new ingredients Cami
            now researches the possibilities of performative objects and storytelling.
        </p>, "https://ignacecami.be/work/", "https://ignacecami.be/work/2012/exoten/"),
        new DescriptionDTO(<p className="bold">Fine Dust</p>, <p>2017, videostill, 08:02 min</p>, <p
            className="info">‘The Palestinian gazelle
            was
            and still is a part of my childhood. I grew up in a house where a photo on the wall showed my father,
            standing proudly next to the Palestinian gazelle. I saw the same kind of gazelle during a family trip with
            my children, only this time “someone” has decided to call this animal “the Israeli Gazelle”. It may be no
            coincidence that in this zoo most of the animals were contaminated with a disease that led to the amputation
            of their part. An image that reflects the case of distortion resulting from the game of role-exchange and
            names’ crossbreeding. A case that engenders a cultural and civilized state with an identity confusion paving
            the way to erase historical memory.<br/>It is the human existence and the civilized patrimony… facing
            identity exclusion and distortion of the personal definition which is printed like a fingerprint in the
            remnant of the individual and collective memory. This is where the game of roles-exchange becomes
            complicated, maybe even the most dangerous idea we’ve thought of until now. A game that aims to erase all
            differences between freedom and slavery, the killer and the victim, resistance and terrorism, the indigenous
            and the occupier, even between death and life.<br/>Identity is often linked to conflicts, that are either
            national, religious or political. However, the issue of the identity is a very personal one, creating a
            personal conflict only. It is related to self-identity for every individual, starting with political, social
            and finally sexual identity. It is the main hereditary substance for the free human intellect and the unique
            mark of the human existence.<br/>Hence, any trial to contain identity will certainly fail… despite the
            visual or current abstraction of the identity and showing it in shapes that differ from its initial<br/>existence…
            it is that mark that no one can change, it is the instinct of things as they were destined to be.’<br/>Manal
            Mahamid (° 1976 Mu’awiya) is a multidisciplinary Palestinian artist working and living in Haifa.
        </p>, "http://manalmahamid.com/website/app/", "")],
    the_arab_culture_association: [new DescriptionDTO(<div><p
        className="bold">Wild Plants of Palestine</p><p
        className="arabic-text bold">{WILD_PLANTS_PALESTINE_ARAB_TITLE}</p></div>, <div><p>2018, Video-essay,
        10:00'"</p><p className="arabic-text">{WILD_PLANTS_PALESTINE_ARAB_METADATA}</p></div>, <div><p
        className="info">Wild Plants of Palestine follows journeys of observational tours solicited by the Palestinian
        Museum and conducted by two professors from Birzeit University to collect photos of and information on the
        Palestinian Flora. The title is adapted from a collection of 123 images (circa 1900 to 1920) of wild flowers in
        Palestine found in the Matson Collection in the Library of Congress. Despite the tendency to trace the wild
        plants, the text in general aims at questioning the territorial extension of what is meant by the term
        “Palestinian”, while standing on insignificant topographical features of the (postcolonial) landscape in West
        Bank. Furthermore, it addresses photography as a practice and a tool of distributing and restricting information
        at once.</p><br/><p className="arabic-text">{WILD_PLANTS_PALESTINE_ARAB_T1}</p><br/><p>Alaa Abu Asad is an
        artist, researcher, and photographer. His
        practice is centred around developing
        and experiencing alternative trajectories where values of (re)presentation, translation, viewing, reading, and
        understanding intersect.</p><br/><p className="arabic-text">{WILD_PLANTS_PALESTINE_ARAB_T2}</p>
    </div>, "https://www.alaaabuasad.com/", "https://www.alaaabuasad.com/wild-plants-of-palestine"),
        new DescriptionDTO(<div><p
                className="bold">"On a zéro en histoire." (01)</p><p
                className="arabic-text bold">{ZERO_HISTOIRE_ARAB_TITLE}</p>
            </div>, <div><p>2020, 46.3 x 38 cm, drawing on world map (Wolters" +
                "School Atlas 1939), beeswax, soot</p><p className="arabic-text">{ZERO_HISTOIRE_ARAB_GRIET_DOBBELS}</p><p
                className="arabic-text">{ZERO_HISTOIRE_ARAB_METADATA}</p></div>,
            <div><p>How
                subjective is a map? How unilaterally is it
                measured and designed?</p><p>Representations of the world are drawn by the most powerful rulers of the
                day and
                reflect the state of the world at a specific moment in time. History is not a straight line.</p><br/>
                <p className="arabic-text">{ZERO_HISTOIRE_ARAB_T1}</p><br/><p>Both drawings are an attempt to question
                    perceptions in both
                    directions rather than just from a Western point of view. At the same time, they also investigate
                    how the landscape changes and the unique aspects that define a ‘place’ or ‘territory’. In these
                    works, Dobbels explores the history of the landscape from various different perspectives –
                    industrial, political, geological, geographical, demographical and archaeological – and how its
                    usage changes over time.</p><br/><p className="arabic-text">{ZERO_HISTOIRE_ARAB_T2}</p><br/><p>The
                    tree depicted in gold
                    leaf in On a zéro en histoire (02) is as old as the 1914 world map. And the only witness to that
                    time and to history.</p>
                <p>On a zéro en histoire comes from the subtitles to a film about young Palestinians entitled Route 181
                    (Fragments of a Journey in Palestine-Israel, 2004, a film by Eyal Sivan and Michel Khleifi.</p><br/>
                <p className="arabic-text">{ZERO_HISTOIRE_ARAB_T3}</p><br/>
                <p>
                    The mediums (pastels, wax crayons, ink) are created by Griet Dobbels from locally sourced materials
                    (beeswax, walnut ink, soil, soot, herbs, spices, …) which she then applies to ancient maps from
                    Imperial
                    times. The local materials allude to a respect for origins, the local and the need for
                    tactility.</p><br/><p className="arabic-text">{ZERO_HISTOIRE_ARAB_T4}</p><br/><p>Griet
                    Dobbels (b. 1964) is a Belgian conceptual artist working in Nazareth.
                    Key concepts in her work are: perception, position, border areas, mapping, measuring and controlling
                    and the
                    dialectic between local and the global, transience and timelessness, nature and culture, beauty and
                    horror,
                    safety and danger, chaos and control, universality and individuality.</p><br/>
                <p className="arabic-text">{ZERO_HISTOIRE_ARAB_T5}</p></div>, "https://grietdobbels.be",
            "")],
    sakakini: [
        new DescriptionDTO(<p className="bold">Gaai</p>, <p>2012, Video loop</p>, <p
            className="info">“Gaai” is a video of a talking bird in a tree. The artist's arm is painted to resemble the
            Eurasian Jay, ‘gaai’ in Dutch. This playful construction of reality is contrasted by the slogan the bird
            repeats. "De Tael is Gansch het Volk" is a historical protest slogan (1836) for the equality of the Dutch
            language, the native tongue of half the population, in the then newly constructed Belgium. The Romantic idea
            that a language is the sole container and conductor of a culture which unifies a community has now turned
            many
            countries to rigid forms of protectionist politics.<br/>For this exhibition the video is accompanied by a
            series
            of crumpled up paper balls.They are meant to be opened and read by the visitors. On them are numerous
            handwritten attempts to translate the slogan from Dutch through English to Arabic. Every possible outcome is
            explored.
            The changing of meaning, possible miscommunication and other forms of transformation are deliberately
            included
            in a search for ‘chance poetry’ and the limits of language as a vessel.<br/>Ignace Cami (Belgium, 1986)
            takes
            his heimat as a starting point to make sculptures, performative objects, installations, storytelling and
            writings dealing with roots, symbols and the contemporary meaning of local history.
        </p>, "https://ignacecami.be", "https://ignacecami.be/work/2012/gaai/"),
        new DescriptionDTO(<p><b>Sun and the Looking Glass</b> - for one easily forgets but the
                tree remembers</p>,
            <p>2020, Video: DCP | super 8, 35mm, HD video and stereo, 23 minutes, no spoken dialogues, poems in English,
                Palestine + Belgium</p>,
            <p className="info">On a land perpetually threatened by colonial appropriation, the transmission of history
                and narratives plays a peculiar and vital role. The Sun and the Looking Glass - for one easily forgets
                but the tree remembers is an essay-film which paints a portrait of a place on a hill above Ein Qiniya, a
                Palestinian village in the West Bank, with two houses from the late Ottoman period. Looking at the
                objects uncovered during their renovations through a magnifying lens, the film performs the creation of
                narratives, through dynamic processes of revelation and disappearance.<br/>Milena Desse is a French
                artist based in Brussels. She works in various formats and mediums, such as media-installations,
                performance, writing, and film. Her artistic research and practice focuses on forms of disappearance and
                revelation, of memorising and forgetting, and on their resonance with storytelling and transmission:
                looking closer at transformation of stories through time and generations.<br/>Her work has been shown
                internationally in FID (Marseille, France), Qattan Foundation (Ramallah, Palestine), GPP (Dubai, United
                Arab Emirates), Khalil Sakakini Cultural Center (Ramallah, Palestine), Khiasma (Paris, France), ARGOS
                center for art and media (Brussels, Belgium), Kaaistudios (Brussels), KHM Gallery (Malmö, Sweden),
                Kunstbiograf at Artoteket (Copenhagen, Denmark), and Netwerk centrum voor hedendaagse kunst (Alost,
                Belgium).<br/>Milena Desse is a founding member of Greyzone Zebra, a collective of artists and
                researchers working on colonial home movies, and a member of ARG (animation research group), an artists
                collective working on the implementation of methodologies and protocols around expanded animation.
            </p>, " http://milenadesse.be", "https://milenadesse.wixsite.com/works/the-sun-and-the-looking-glass"),
        new DescriptionDTO(<p className="bold"><strike>Tutorial: How to disappear, become an image</strike></p>,
            <p>2015, YouTube video, 5’16</p>, <p className="info">The artist tried to make herself disappear by
                assimilating with a green screen background through painting her face and body with green paint, green
                screens are used in image making as backgrounds to be replaced with other different backgrounds. This
                performative act that she will be doing will be presented as a youtube instructional tutorial on how to
                disappear, by the end of the tutorial the green painted artist and the green background are replaced
                through editing by a series of famous images that reduce people, catastrophes and events into mass
                distributed iconic images.<br/>Mirna Bamieh is from Jerusalem/ Palestine. She obtained a B.A in
                Psychology from Birzeit University in Ramallah (2002-06). M.F.A. in Fine Arts at Bezalel Academy for
                Arts and Design in Jerusalem (2011-2013). Ashkal Alwan HomeWorks study program in Beirut (2013/14).<br/>Her
                work attempts to understand the politics of disappearance in their relation to the ever-shifting
                politics, while equally questioning notions of land, geographies of in-between temporality. Two years
                ago she went to cooking school and obtained a Diploma in Professional Cooking which made her develop
                works that use the mediums of storytelling and food for creating socially engaged projects through her
                art practice. Mirna aspires to create artworks where food/eating/sharing create an innovative, and fresh
                way for people to experience themselves and their surroundings, such projects are : Maskan Apartment
                Project, Potato Talks Project, and Palestine Hosting Society which was her focus for the past three
                years.<br/>She participated in a number of local and international shows and festivals, such as: Where
                no
                Wall Remains Biennial/ New York Nov 2019, Qalandiya International/ Palestine (all editions), Museum
                of Fine Arts/ Gifu, Japan Nov 2017. Tokyo Wonder Site/ Tokyo Jul 2016, Apr 2017. IFFR Rotterdam Film
                Festival- Rotterdam/ Jan 2017. Under the last sky Festival - Berlin/ Sep 2016. Halcyon, Transart
                Berlin Triennial - Berlin/ Aug 2016, Mosaic Rooms/ London Aug 2016. Tokyo Wonder Site Exhibition -
                Tokyo/ July 2016. Palest’in & out - Paris/ July 2016. Marrakech Biennial - Marrakech/ March 2016.
                Afterwardsness Solo show, al Hosh Gallery- Jerusalem Jan 2016. I spy with my little eye - London,
                Madrid/2015. Alfilm festival - Berlin/ 2015. Exposure 2014 - Beirut Art Center. Qalandiya
                International - Palestine/ 2012/2014/2016. Encounters Film Festival - Bristol/ 2014. Cork Film
                Festival - Ireland/ 2014. Eye on Palestine Art and Film Festival - Brussels/ 2014. “Artist of the
                Year Award 2012” with A.M Qattan Foundation - Ramallah/ 2012 and Mosaic Rooms, London/ 2013.<br/>She
                received several fellowships and awards such as: CEC ArtsLink International/New York (Autumn 2020),
                Jodi Guggenheim Award/Zurich (2021), Visible Award (long list), 2017, ArteEast Fellowship, 2015.
                Artist of the Year Award, 2012. Global Art Forum Fellowship, Art Dubai, 2011.
            </p>, "https://mirnabamieh.info", "https://palestinehostingsociety.com"),
        new DescriptionDTO(<p className="bold">Staring ll</p>, <p>2019, Sculpture in resin and folded Plexiglass, 45 x
            17 x 14 cm
        </p>, <p className="info">Time: Mother’s Day<br/>Location: Ayn al-Tineh, also called the “Hill of Shouts” on the
            ceasefire line bordering the village.<br/>Through the telescope I was looking for a colour in the place we
            agreed upon on the opposite side. Families used to gather on both sides of the line, and over less than one
            kilometer, people met and exchanged news. My Mother was waving with the blue scarf.<br/>Shada Safadi (1982)
            was born in Majdal Shams, the occupied Syrian Golan Heights. She currently lives and works between her
            hometown and the West Bank.<br/>After finishing a two years class in painting and etching at Adham Ismail
            Institute in Damascus in 2004, she graduated from Damascus University with a degree in fine arts from the
            department of painting in 2005.<br/>She is a founding member of Fateh Al Mudarris Center for Arts and
            Culture, located in the occupied Golan Heights. She has participated in multiple exhibitions. Her work has
            been featured in exhibitions in Damascus, Aleppo (Syria), Jerusalem, Ramallah, Birzeit, Bethlehem (West
            Bank), Umea (Sweden) and London. Recently, she participated in the Syrian Cultural Caravan, with exhibitions
            in France, Germany, Norway, Spain and Belgium.<br/>Shada won the third place of the ‘Young Artist of the
            year’ award for her work ‘In the Presence of the Crow’ with A.M. Qattan Foundation in 2008. In addition, she
            was an artist in residence for three months in Cité Internationale des Arts in Paris in 2014.
        </p>, "https://shadasafadi.blogspot.com/", ""),
        new DescriptionDTO(<p className="bold">The Invention of Night</p>, <p>2020, Video, twenty- one minutes, English
            with Arabic subtitles</p>, <p className="info">Covid-19 has accelerated the global shift into the virtual
            world, while also forcing us to re-examine our relationship with ‘time’. Within this continued global
            uncertainty, Swedish performance storyteller Mats Rehnman and Palestinian visual artist and filmmaker Shuruq
            Harb explore the concept of ‘time’ in relation to human grief, wakefulness and sleep, persistence and
            resilience. Taking the Indian mythology entitled How Night Came into Being as its starting point, this
            experimental video combines spoken word with drawing, found images and video effects to create new
            possibilities for story telling online. This work was commissioned by Al-Balad Theater for the 13th edition
            of Hakaya Festival 2020.<br/>Shuruq Harb is an artist, filmmaker and writer. She is the co-founder of
            several independent art initiatives such as ArtTerritories (2010-2017) and The River Has Two Banks
            (2012-2017). Her artistic practice focuses on online visual culture and traces subversive routes for the
            circulation of images and goods. Her film The White Elephant received the award for best short film at
            Cinema du Reel Festival in Paris, 2018, and was shortlisted for the Hamburg International Short Film
            Festival, 2019. Most recently she published her first short story <a className="as-text"
                                                                                 href="http://mezosfera.org/and-this-is-the-object-that-i-found/">“and
                this is the object that I
                found”</a> (2020) at Mezosfera. Her upcoming solo exhibition at Beirut Art Center brings together for
            the first time several of her artworks in one exhibition. She is the recipient of the Han Nefkens Foundation
            – Fundació Antoni Tàpies Video Art Production Award (2019).<br/>Mats Rehnman is a professional storyteller,
            living in Stockholm. Since 1987 he has performed all around Sweden for children, youth and adult-audiences
            on commission from cultural centers, schools, libraries, theatres, companies, radio, festivals with
            traditional and original stories. He is also involved in international cooperations with storytellers and
            groups. He is often seen as a featured performer at festivals and storytelling clubs in countries around the
            world. In 2001 he received the Mickels Prize in the storytelling festival in Ljungby. Mats is a member of
            the company Fabula Storytelling, a professional group located in Stockholm.
        </p>, "https://www.storytelling.se", "")],
    glossolalia: [new DescriptionDTO(<p className="bold">ثَرثَرة / Glossolalia</p>,
        <div>
            <p>Single-channel video with audio, 14'37", 2021</p>
            {GLOSSOLALIA_METADATA}
        </div>,
        <div>
            <p>Glossolalia reveals poetic diversity and is an ode to the pleasure of looking at images and listening to
                sounds. In Glossolalia, tonal language or speaking in tongues transforms difficult communication and
                misunderstandings into playful and refined poetry. References to the online gatherings of Under the
                Tower / Onder de Kerktoren / {ARABIC_TITLE} exhibition-project are present but never overbearing.
                Glossolalia
                is a conversation between the artists, and through audiovisual elements it offers a window on the world,
                an invite to a contemplative journey.
            </p>
            <br/>
            {GLOSSOLALIA_P1}
            <br/>
            <p>Glossolalia is a form of uncommon speech behaviour and is also known as ‘tonal language’ or ‘speaking in
                tongues’. A visual language can be clear to one person and totally ambiguous to another, yet arousing
                their curiosity. Translation creates a Tower of Babel. Language, whether visual or textual, evokes
                associations that are rooted in history, context and culture.
            </p>
            <br/>
            {GLOSSOLALIA_P2}
            <br/>
            <p><b>Alaa Abu Asad</b> is an artist, researcher and photographer. His practice is centred around developing
                and experiencing alternative trajectories in which values of (re)presentation, translation, viewing,
                reading and understanding intersect.<br/>
                The multi-disciplinary practice of <b>Ignace Cami</b> is deeply rooted in folklore and explores identity
                and
                tradition. He takes his own heimat as a starting point for storytelling, sculptures, performative
                objects or installations.
            </p>
            <br/>
            {GLOSSOLALIA_P3}
            {GLOSSOLALIA_P4}
            <br/>
            <p>This video is produced as part of the exhibition project Under the Tower / Onder de Kerktoren
                / {ARABIC_TITLE} and inspired by its online conversations, with the participation of Griet Dobbels, Els
                Vermeersch, Essa Grayeb, Jack Persekian, Els Vanden Meersch, Manal Mahamid, Stef Van Bellingen, Milena
                Desse, Mirna Bamieh, Shada Safadi, Shuruq Harb, Renad Shqeirat, Jessy Rahman, Rosh Abdelfatah, Blanka De
                Bruyne, Sam Eggermont en Stefaan Van Biesen.<br/>
                Funded by Flanders, State of the Art
            </p>
            <br/>
            {GLOSSOLALIA_P5}
            {GLOSSOLALIA_P6}
        </div>,
        "",
        <div><a href="https://alaaabuasad.com/">https://alaaabuasad.com/</a><br/><a
            href="https://ignacecami.be/">https://ignacecami.be/</a></div>
    )
    ],
}

const MARKER_DTO_LIST = [
    new MarkerDTO("Emergent", "emergent", "https://emergent.be", "01/11/2020 - 31/01/2021", ['ESSA GRAYEB', 'GRIET DOBBELS'], "51.072791", "2.662313", ExhibitionStatus.PAST),
    new MarkerDTO("Al Ma'Mal Foundation", "almamal", "https://www.almamalfoundation.org", "Coming soon: dates - artists", [], "31.778254", "35.226485", ExhibitionStatus.INVISIBLE),
    new MarkerDTO("WARP", "warp", "https://www.warp-art.be/nl/programma", "09/01/2021 - 21/02/2021", ['IGNACE CAMI', 'MANAL MAHAMID'], "51.164154", "4.143897", ExhibitionStatus.PAST),
    new MarkerDTO("The Arab Culture Association", "the_arab_culture_association", "https://arabca.net", "08/03/2021 - 20/04/2021", ['ALAA ABU ASAD', 'GRIET DOBBELS'], "32.815384", "34.995862", ExhibitionStatus.PAST),
    new MarkerDTO("Khalil Sakakini CC", "sakakini", "http://www.sakakini.org/en/", "20/04/2021 - 20/05/2021", ['IGNACE CAMI', 'MILENA DESSE', 'MIRNA BAMIEH', 'SHADA SAFADI', 'SHURUQ HARB & MATS REHNMAN'], "31.898938", "35.201732", ExhibitionStatus.PAST),
    new MarkerDTO("ثَرثَرة / Glossolalia - Online Art video", "glossolalia", "", "20/05/2021 - 17/07/2021", ["ALAA ABU ASAD & IGNACE CAMI"], "50.849113", "5.688546", ExhibitionStatus.PAST, "", true, 7),
]

export {DESCRIPTION_LIST, MARKER_DTO_LIST, HOMEPAGE_LIST};
