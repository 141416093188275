import React from "react";
import AbstractMarker from "../../components/MapCard/AbstractMarker";

class BlueprintMarker extends React.Component {

    constructor(props) {
        super(props);
        this.id = "10";
        this.title = "Blueprint*"
        this.url = "blueprint";
        this.externalUrl = "";
        this.artists = ["Presentation | Talk"];
        this.dates = "23 October 2021";
        this.lat = "51.20946269999361";
        this.long = "4.455515455230869";
    }

    render() {
        return (
            <AbstractMarker id={this.id} title={this.title} url={this.url} externalUrl={this.externalUrl}
                            artists={this.artists} dates={this.dates} lat={this.lat} long={this.long}/>
        );
    }
}

export default BlueprintMarker;