import React from "react";

class IncompleteExhibition extends React.Component {

    constructor(props) {
        super(props);
        this.markerDTO = this.props.markerDTO;
    }

    render() {
        return (
            <div>
                <a href={this.markerDTO.externalUrl} rel="noopener noreferrer" target="_blank"><p
                    className="left-align bold">{this.markerDTO.title}</p></a>
                <br/>
                <p className="left-align">{this.markerDTO.dates}</p>
                <br/>
                {this.markerDTO.description[0].extraInfo}
                {this.markerDTO.extraInfo}
            </div>
        )
    }
}

export default IncompleteExhibition;
