import React from "react";

class TitleParagraph extends React.Component {
    render() {
        return (
        <a href={this.props.externalUrl} rel="noopener noreferrer" target="_blank">
                    <p className="left-align bold">{this.props.title}</p>
        </a>
        )
    }
}

export default TitleParagraph;