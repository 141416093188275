export const WILD_PLANTS_PALESTINE_ARAB_TITLE = "زهور فلسطين البريّة";
export const WILD_PLANTS_PALESTINE_ARAB_METADATA = "مقالة-فيديو، 2018، 10 دقائق";
export const WILD_PLANTS_PALESTINE_ARAB_T1 = "تتتبّع مقالة-فيديو \"زهور فلسطين البريّة\" رحلات جولات استكشافيّة لتجميع الصور والمعلومات عن النباتات الفلسطينيّة نسّقها المتحف الفلسطيني بمرافقة أستاذين من جامعة بيرزيت. أُقتبس العنوان من مجموعة ماتسون (في مكتبة الكونغرس) التي تشمل 123 صورة لزهور بريّة في فلسطين أُلتقطت بين الأعوام 1900 و 1920. على الرغم من الميل إلى اقتفاء أثر النباتات البريّة،يهدف نصّ المقالة بشكل عام إلى التشكيك في استخدام مصطلح \"فلسطيني\" فيما يتعلّق بالامتداد الإقليمي والسياسي للمنطقة، وتحاول الصور إبراز سمات طوبوغرافيّة \"أقل أهميّة\" في المشهد الطبيعي للضفّة الغربيّة بعد الإستعمار. تتناول مقالة-الفيديو كذلك إمكانيّة تحوّل التصوير الفوتوغرافي إلى ممارسة وأداة في توزيع وتقييد المعلومات في آنٍ واحد.";
export const WILD_PLANTS_PALESTINE_ARAB_T2 = "علاء أبو أسعدفنّان وباحث ومصوّر من فلسطين، تتركّز أعماله حول قيم ومفاهيم الصورة والترجمة، وعمليّة المشاهدة والقراءة.";

export const ZERO_HISTOIRE_ARAB_GRIET_DOBBELS = "غريت دوبلس";
export const ZERO_HISTOIRE_ARAB_TITLE = "\"حصلنا على صفر في التاريخ\". (01)";
export const ZERO_HISTOIRE_ARAB_METADATA = "46.3x38 ،2020 سم، رسم على خارطة العالم (فولتيرس سكوول أطلس، 1939)، شمع النحل وسِناج";
export const ZERO_HISTOIRE_ARAB_T1 = "ما مدى موضوعيّة الخريطة؟ وهل تُحسب قياساتها وتصمّم من منظور أحادي؟ يخطّ أقوى الحكّام رسومًا ممثّلة للعالم لتعكس الوضح الحالي في لحظة معيّنة من الزمن؛ فالتاريخ ليس خطًا مستقيما.";
export const ZERO_HISTOIRE_ARAB_T2 = "تعبّر كلا الرسمتين عن محاولة للتشكيك في التصوّرات في كلا الاتجاهين، وليس فقط من وجهة نظر غربيّة. تقوم هذة الأعمال في الوقت نفسه بالتحقيق في كيفيّة تغيّر المشهد الطبيعيّ والجوانب الاستثنائيّة التي تحدد \"المكان\" أو \"المنطقة\". تستكشف دوبلس من خلال هذه الأعمال تاريخ المشهد الطبيعيّ من وجهات نظر متنوّعة: صناعية وسياسية وجيولوجية وجغرافية وديموغرافية وأثرية؛ وكيف يتغيّر استخدامها بمرور الوقت.";
export const ZERO_HISTOIRE_ARAB_T3 = "عمر الشجرة المرسومة بورق الذهب في \"حصلنا على صفر في التاريخ\" (02) هو  من عمر خريطة العالم - منذ عام 1914؛ وهي الشاهد الوحيد لهاك الوقت والتاريخ. العنوان \"حصلنا على صفر في التاريخ\" مأخوذ من الترجمة المرافقة لفيلم إيال سيفان وميشيل خليفة عن الشباب الفلسطينيّ عنوانه طريق 181 (مقتطفات من رحلة في فلسطين وإسرائيل، 2004).";
export const ZERO_HISTOIRE_ARAB_T4 = "حضّرت غريت دوبلس الوسائط المستخدمة في العمل - الباستيل وأقلام الشمع والحبر - من مواد محليّة المصدر (مثل: شمع العسل وحبر الجوز وتراب وسِناج وأعشاب وتوابل ...)؛ ووضعتها بعد ذلك على الخرائط القديمة من العصور الإمبيرياليّة. تشير المواد المحلية إلى احترام الأصول والمحليّ والحاجة إلى اللمس.";
export const ZERO_HISTOIRE_ARAB_T5 = "غريت دوبلس (مواليد 1964) فنّانة مفاهيميّة بلجيكيّة، تعمل في مدينة الناصرة (نَزارِت) في بلجيكا. تشمل المفاهيم الأساسيّة في أعمالها الإدراك والموقع والمناطق الحدودة ورسم الخرائط والقايس والتحكّم، ودياليكتيّة المحليّ والعالميّ، والزوال والخلود، والطبيعة والثقافة، والجمال والرعب، والأمان والخطر، والفوضى والسيطرة، ومفهوم العالميّة مقابل الفرديّة.";
