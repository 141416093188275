import React from "react";
import {ARABIC_TITLE} from "../../Constants";
import {Link} from "react-router-dom";

class DarJacirHomePage extends React.Component {

    constructor(props) {
        super(props);
        this.id = "06";
        this.title = "Dar Jacir"
        this.url1 = "artist_book_under_tower";
        this.url2 = "glossolalia";
        this.text1 = `Bethlehem : 01/10/2022 , 6 PM : Launch ARTIST’S BOOK Under the Tower / Onder de Kerktoren / ${ARABIC_TITLE}`;
        this.text2 = "with screening art video ثَرثَرة / Glossolalia (ALAA ABU ASAD and IGNACE CAMI)";
    }

    generateRow = () => {
        let text = [];
        text.push("(" + this.id + ")");
        text.push(this.title);
        text = text.join(" ") + ", ";
        text += this.text1;
        return text;
    }

    render() {
        return (
            <tr>
                <td>
                    {this.generateRow()}<Link to={this.url1}> more info</Link>
                    <br/>
                    {this.text2}<Link to={this.url2}> more info</Link>
                </td>
            </tr>
        );
    }
}

export default DarJacirHomePage;
