import React from "react";
import SpecificArtwork from "./SpecificArtwork";
import TitleParagraph from "./Paragraphs/TitleParagraph";

class SpecificExhibitionOld extends React.Component {

    constructor(props) {
        super(props);
        this.markerDTO = this.props.markerDTO;
        this.descriptionDTO = this.markerDTO.description;
    }

    makeArtistsString = () => {
        return (this.markerDTO.artists.join(" - "));
    }

    generateArtworks = () => {
        const artworkList = [];
        for (const i in this.descriptionDTO) {
            if (this.descriptionDTO.hasOwnProperty(i)) {
                artworkList.push(<SpecificArtwork markerDTO={this.markerDTO} number={i} key={i + 10000}/>)
            }
        }
        return artworkList
    }

    makeMobileList = () => {
        const list = [];
        for (const [i, artwork] of this.generateArtworks().entries()) {
            list.push(artwork);
            list.push(<br key={i}/>);
        }
        list.pop();
        return list;
    }

    makeTableRows = (list) => {
        const table = [];
        let even = true;
        if (list.length % 2 === 1) {
            even = false;
        }
        while (list.length >= 2) { // A pair of artworks can be generated
            table.push(<tr key={list.length + 90000}>
                <td>{list[0]}</td>
                <td>{list[1]}</td>
            </tr>);
            list.splice(0, 2);
        }
        if (!even) { // generate last artwork
            table.push(<tr key={1 + 90000}>
                <td>{list[0]}</td>
            </tr>);
        }
        return table;
    }

    render() {
        return (
            <div>
                <TitleParagraph externalUrl={this.markerDTO.externalUrl} title={this.markerDTO.title}/>
                <br/>
                <p className="left-align">{this.makeArtistsString()}</p>
                <br/>
                <p className="left-align">{this.markerDTO.dates}</p>
                <br/>
                <table className="hide-on-med-and-down">
                    <tbody>
                    {this.makeTableRows(this.generateArtworks())}
                    </tbody>
                </table>
                <div className="hide-on-large-only">
                    {this.makeMobileList()}
                </div>
            </div>
        )
    }
}

export default SpecificExhibitionOld;