import React from "react";
import EventMap from "./EventMap";
import {MAP_ID, MENU_ITEMS} from "../../Constants";

class MapCard extends React.Component {

    constructor(props) {
        super(props);
        this.url = window.location.pathname;
        this.menuItemsToHide = MENU_ITEMS;
        this.menuItemsToHide.push(""); // Don't show map on homepage
        this.state = {visible: this.updateVisibility()}
    }

    updateVisibility = () => {
        return (!this.menuItemsToHide.includes(window.location.pathname.substring(1))) // If menuItemsToHide does not have the current URL, show the map
        // But because "world map" has a space in it, and the url doesn't -> it still shows on /worldmap.
        // This is somewhat of a hack, more by accident, but it works so I'll leave it like this.
    }

    componentDidMount() {
        // While not ideal, this function won't have a big measurable performance impact
        setInterval(() => {
            if (this.url !== window.location.pathname) {
                this.url = window.location.pathname;
                this.setState({visible: this.updateVisibility()});
            }
        }, 50);
    }


    render() {
        if (this.state.visible) {
            return (
                <div id={MAP_ID} className="section">
                    <div className="row container center">
                        <div className="col s12 m12">
                            <EventMap/>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (<div className="hide">map</div>)
        }
    }
}

export default MapCard;