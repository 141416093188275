import React from "react";
import {COOKIE_MODAL_ID} from "../Constants";
import {Link} from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/analytics';

class CookieModal extends React.Component {

    enableAnalytics = () => {
        const firebaseConfig = {
            apiKey: "AIzaSyDnUJthRt7oPLbNsMzODH2sYjL__3BblRw",
            authDomain: "onder-de-kerktoren.firebaseapp.com",
            databaseURL: "https://onder-de-kerktoren.firebaseio.com",
            projectId: "onder-de-kerktoren",
            storageBucket: "onder-de-kerktoren.appspot.com",
            messagingSenderId: "238996486359",
            appId: "1:238996486359:web:3f7600c9fc9da92059d892",
            measurementId: "G-MG6HPGYQWF"
        };
        firebase.initializeApp(firebaseConfig);
        firebase.analytics.isSupported().then(r => {
            if (r) {
                firebase.analytics();
            }
        })
        document.cookie = 'cookie_accept=1; domain=underthechurchtower.org; max-age=2629743; SameSite=Lax'
    }

    render() {
        return (
            <div id={COOKIE_MODAL_ID} className="modal">
                <div className="modal-content">
                    <h4>Accept cookies?</h4>
                    <p>"UNDER THE TOWER" uses JavaScript, cookies and comparable technologies to improve its services. We can save and analyse visitor's behaviour and add this information to internal user profiles. <b>Any data collected will never be sold to third parties.</b></p>
                    <p>To visit this site, you must accept cookies by clicking "Agree".</p>
                    <p>For more information about cookies used on this site, please <Link to="contact">contact us</Link>.</p>
                </div>
                <div className="modal-footer">
                    <button onClick={this.enableAnalytics} className="modal-close waves-effect waves-green btn darkblue">Agree</button>
                </div>
            </div>
        )
    }
}

export default CookieModal;