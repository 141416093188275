import React from "react";
import {Link} from "react-router-dom";
import AbstractCard from "../AbstractCard/AbstractCard";
import ExhibitionsListOld from "./ExhibitionsListOld";
import {ExhibitionStatus} from "../MapCard/MarkerDTO";
import QuartairHomePage from "../../views/quartair/QuartairHomePage";
import BlueprintHomePage from "../../views/blueprint/BlueprintHomePage";
import ArtistBookUnderTowerHomePage from "../../views/artist_book_under_tower/ArtistBookUnderTowerHomePage";
import TerVestenHomePage from "../../views/tervesten/TerVestenHomePage";
import DarJacirHomePage from "../../views/darjacir/DarJacirHomePage";

class HomePageCard extends React.Component {

    render() {
        return (
            <AbstractCard className="black-border">
                <div className="card-image">
                    <img src={require("../../images/titlecard/worldmap.jpg")} alt="world map of the world in 1914"/>
                </div>
                <div className="card-content">
                    <div className="left-align">
                        <p className="bold">Is there a difference between digital assumptions and physical
                            experience?</p>
                        <br/>
                        <p>ONDER DE KERKTOREN / UNDER THE TOWER is an exhibition project that links a broad range of
                            artists, curators and locations so far in Belgium, the Netherlands and Palestine.</p>
                        <p>Numerous initiatives, exhibitions and residencies have been cancelled or postponed due to
                            COVID-19. The momentum has been lost but the need remains: to create, to connect and to
                            exhibit.</p>
                        <br/>
                        <p>ONDER DE KERKTOREN / UNDER THE TOWER is more than the sum of its parts.</p>
                        <br/>
                        <Link to="about">More info</Link>
                        <br/>
                        <Link to="process">More info on Process</Link>
                        <br/>
                        <Link to="worldmap">Locations on world map</Link>
                        <br/>
                        <Link to="artist_book_under_tower">ARTIST'S BOOK</Link>
                    </div>
                    <br/>
                    <div className="left-align">
                        <p>CURRENT EXHIBITIONS</p>

                        <div>
                            <table>
                                <tbody>
                                    <ExhibitionsListOld exhibitionStatus={ExhibitionStatus.CURRENT}/>
                                </tbody>
                            </table>
                        </div>
                        <br/>
                        <p>UPCOMING</p>
                        <div>
                            <table>
                                <tbody>
                                    <ExhibitionsListOld exhibitionStatus={ExhibitionStatus.UPCOMING}/>
                                    <DarJacirHomePage/>
                                </tbody>
                            </table>
                        </div>
                        <br/>
                        <p>PAST EXHIBITIONS</p>
                        <div>
                            <table>
                                <tbody>
                                    <ExhibitionsListOld exhibitionStatus={ExhibitionStatus.PAST}/>
                                    <QuartairHomePage/>
                                    <TerVestenHomePage/>
                                    <BlueprintHomePage/>
                                    <ArtistBookUnderTowerHomePage/>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </AbstractCard>
        );
    }
}

export default HomePageCard;
