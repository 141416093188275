import React from "react";

class ArtistsParagraph extends React.Component {

    makeArtistsString = () => {
        return (this.props.artists.join(" - "));
    }

    render() {
        return <p className="left-align">{this.makeArtistsString()}</p>
    }
}

export default ArtistsParagraph;