import React from "react";
import {Marker, Popup} from "react-leaflet";
import {ARABIC_TITLE} from "../../Constants";
import {Link} from "react-router-dom";

class ArtistBookUnderTowerMarker extends React.Component {

    constructor(props) {
        super(props);
        this.id = "11";
        this.title = "Artist's Book 'Under the Tower'"
        this.url = "artist_book_under_tower";
        this.externalUrl = "artist_book_under_tower";
        this.artists = [""];
        this.dates = "";
        this.lat = "51.049740";
        this.long = "3.729251";
    }

    render() {
        return (
            <Marker position={[this.lat, this.long]}>
                <Popup>
                    <p className="marker-title">{ARABIC_TITLE}<br/>({this.id}) Onder De Kerktoren / Under the Tower</p>
                    <a href={this.externalUrl} rel="noopener noreferrer" target="_blank"><p className="bold">{this.title}</p></a>
                    <br/>
                    <br/>
                    <br/>
                    <Link to={"/" + this.url}>more info coming soon</Link>
                </Popup>
            </Marker>
        );
    }
}

export default ArtistBookUnderTowerMarker;
