import React from "react";
import ExhibitionEntry from "../../components/HomePage/ExhibitionEntry";

class QuartairHomePage extends React.Component {

    constructor(props) {
        super(props);
        this.id = "08";
        this.title = "Quartair & Arab Film Festival";
        this.url = "quartair_and_arab_film_festival";
        this.text = "Den Haag / Rotterdam: ILONA SENGHORE - PAUL DONKER-DUYVIS - ROSH ABDELFATAH & RODI KHALIL : 02/10/2021 - 17/10/2021";
    }

    render() {
        return (
            <ExhibitionEntry id={this.id} title={this.title} text={this.text} url={this.url}/>
        );
    }
}

export default QuartairHomePage;