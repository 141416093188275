import React from "react";
import AbstractMarker from "../../components/MapCard/AbstractMarker";

class ArabFilmMarker extends React.Component {

    constructor(props) {
        super(props);
        this.id = "08";
        this.title = "Arab Film Festival & Quartair"
        this.url = "quartair_and_arab_film_festival";
        this.externalUrl = "https://arabfilmfestival.nl";
        this.artists = ["ILONA SENGHORE", "PAUL DONKER-DUYVIS", "ROSH ABDELFATAH & RODI KHALIL"];
        this.dates = "02/10/2021 - 17/10/2021";
        this.lat = "51.904864351643";
        this.long = "4.486987956572894";
    }

    render() {
        return (
            <AbstractMarker id={this.id} title={this.title} url={this.url} externalUrl={this.externalUrl}
                            artists={this.artists} dates={this.dates} lat={this.lat} long={this.long}/>
        );
    }
}

export default ArabFilmMarker;