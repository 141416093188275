import {DESCRIPTION_LIST, HOMEPAGE_LIST} from "../../data/MarkerData";

export const ExhibitionStatus = Object.freeze({"UPCOMING":1, "CURRENT":2, "PAST":3, "INVISIBLE": 4})

export class MarkerDTO {
    constructor(title, url, externalUrl, dates, artists, lat , long, exhibitionStatus=ExhibitionStatus.CURRENT, extraInfo="", isComplete=true, id=0) {
        this.title = title;
        this.url = url;
        this.externalUrl = externalUrl;
        this.dates = dates;
        this.artists = artists;
        this.lat = lat;
        this.long = long;
        this.exhibitionStatus = exhibitionStatus;
        this.extraInfo = extraInfo;
        this.isComplete = isComplete;
        this.description = DESCRIPTION_LIST[url];
        this.id = id.toString().padStart(2, "0"); // Shouldn't ever be the default "00"
        this.homepage = HOMEPAGE_LIST[this.url]
    }

    setID = (id) => {
        if (this.id === "00") {
            this.id = (id + 1).toString().padStart(2, "0");
        }
    }

    getID = () => {
        if (this.id === undefined) {
            console.warn("ID undefined");
        }
        return this.id;
    }
}
