import React from "react";
import ExhibitionEntry from "../../components/HomePage/ExhibitionEntry";

class BlueprintHomePage extends React.Component {

    constructor(props) {
        super(props);
        this.id = "10";
        this.title = "Blueprint*";
        this.url = "blueprint";
        this.text = "Antwerp : 23 October 2021 : presentation / talk: 19:00";
    }

    render() {
        return (
            <ExhibitionEntry id={this.id} title={this.title} text={this.text} url={this.url}/>
        );
    }
}

export default BlueprintHomePage;