import React from "react";
import AbstractCard from "./AbstractCard/AbstractCard";

class About extends React.Component {
    render() {
        return (
            <AbstractCard>
                <div className="card-content">
                    <div className="left-align">
                        <img className="responsive-img" src={require("../images/about/atelier.jpg")}
                             alt="Griet's atelier"/>
                        <h6>ONDER DE KERKTOREN / UNDER THE TOWER</h6>
                        <br/>
                        <p>Is there a difference between digital assumptions and physical experience?</p>
                        <br/>
                        <p className="bold">The need</p>
                        <p>ONDER DE KERKTOREN / UNDER THE TOWER is an exhibition project that links a broad range of
                            artists, curators and locations so far in Belgium, the Netherlands and Palestine.
                        </p>
                        <p>
                            Countless initiatives, exhibitions and residencies have been cancelled due to Covid. The
                            momentum is gone but the need remains: to create work, meet people and exhibit.
                            ONDER DE KERKTOREN / UNDER THE TOWER is more than the sum of its parts.
                        </p>
                        <br/>
                        <p className="bold">The church tower and the curfew</p>
                        <p>‘Onder de Kerktoren (Under the church tower)’ is a typical Flemish expression that means
                            small-scale or navel-gazing. Universality and open-mindedness sit at the opposite end of the
                            spectrum. These two extremes are diametrically opposed to one another. The concepts of local
                            and global have a new ring to them. Western European citizens are experiencing the reality
                            of lockdowns for the first time since the Second World War. People in the Middle East are
                            more familiar with the idea of confinement and the imposition of curfews. We are confronted,
                            quite literally, with our perceptions of our current location. How do we see things from a
                            certain position? What do people think about limited freedom or the intervention of public
                            authorities on people’s liberty within the public space? Is there a difference between
                            digital assumptions and physical experience?
                        </p>
                        <br/>
                        <p className="bold">Proliferation</p>
                        <p>As each contact introduces new artists to the project, ONDER DE KERKTOREN / UNDER THE TOWER
                            gradually
                            expands into a much larger network. This multiplicity becomes the form, or the method, of
                            making the work visible. Collaborations arise in multiple locations, with all kinds of
                            artists, both in Belgian region and in the Middle East. Small, moveable exhibitions spring
                            up in various institutions, fragmented yet localised. A wall, a drawing, a live-streaming
                            event or a video are created ‘under the church tower’ some 5,000 km away. The concepts of
                            local and global acquire a new form.</p>
                        <br/>
                        <p className="bold">Fragmentation and repair</p>
                        <p>The core concepts are: perception, position, border areas and mapping, measuring and
                            controlling. This generates an immense work in progress. Artists regain visibility: both
                            physically and digitally. The form becomes the content. The fragmentation forms the whole
                            and the multiplicity is the strength.
                        </p>
                        <br/>
                        <p className="bold">ONDER DE KERKTOREN / UNDER THE TOWER is more than the sum of its parts.</p>
                    </div>
                </div>
            </AbstractCard>
        )
    }
}

export default About;
