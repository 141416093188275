import React from "react";
import AbstractCard from "../AbstractCard/AbstractCard";
import {MAP_ID} from "../../Constants";
import ExhibitionTitleBar from "./ExhibitionTitleBar";

class AbstractExhibitionCard extends React.Component {

    loadAndScroll = () => {
        setTimeout(this.scrollDown, 100);
    }

    scrollDown = () => {
        // Subtract 80 at top, because of navbar
        const mapCard = document.getElementById(MAP_ID);
        window.scrollBy({
            top: mapCard.getBoundingClientRect().bottom - 80,
            left: 0,
            behavior: "smooth"
        });
    }

    componentDidMount() {
        this.loadAndScroll();
    }

    render() {
        return (
            <AbstractCard>
                <div className="card-content exhibition-card">
                    <ExhibitionTitleBar id={this.props.id}/>
                    <br/>
                    {this.props.children}
                </div>
            </AbstractCard>
        );
    }
}

export default AbstractExhibitionCard;
