import React from "react";
import AbstractCard from "./AbstractCard/AbstractCard";
import CardTitle from "./AbstractCard/CardTitle";
import {ARABIC_TITLE} from "../Constants";

class Process extends React.Component {
    render() {
        return (
            <AbstractCard>
                <div className="card-content">
                    <CardTitle>Process</CardTitle>
                    <div className="left-align">
                        <h6><b>{ARABIC_TITLE}</b></h6>
                        <h6><b>Onder de Kerktoren</b></h6>
                        <h6><b>Under the Tower</b></h6>
                        <br/>
                        <p className="bold">Is there a difference between digital assumptions and physical experience?</p>
                        <br/>
                        <p>{ARABIC_TITLE} / ONDER DEKERKTOREN / UNDER THE TOWER began as an exhibition project aimed at connecting the various artists, curators and locations involved in the project to date. The focus is currently on Belgium, the Netherlands and Palestine. Countless initiatives, exhibitions and residencies have been cancelled because of Covid. The momentum has gone but the need remains: to create work, meet people and exhibit.</p>
                        <br/>
                        <p>Artists from the different regions are exhibiting their work in a variety of locations: a wall, a room or a space in an existing organisation. The visibility of the overall project grows via texts and images published on the website and social media, proliferating like dots on a world map. This creates a large work in progress. Each participant introduces new contacts. The exhibitions are given the title {ARABIC_TITLE} / ONDER DEKERKTOREN / UNDER THE TOWER and a sequential number</p>
                        <br/>
                        <p>The themes are: taking a position or being forced to take a position. What is the perspective or the viewpoint? What happens when we connect multiple perspectives? How do we interpret each other? The ‘we/they’ manner of thinking? Depending on the viewpoint, ‘we/they’ becomes ‘they/we’, and vice versa.</p>
                        <br/>
                        <p>{ARABIC_TITLE} / ONDER DEKERKTOREN / UNDER THE TOWER is the result of an organic process. The many encounters through Zoom have revealed that language is read and heard differently, depending on the position you find yourself in. The road to understanding one another is sometimes confusing and difficult. The conversations are usually intense. Often pure poetry emerges</p>
                        <br/>
                        <p>The very title of the project triggered a discussion about language and culture. ‘Onder de kerktoren (Under the church tower)’ is a Flemish expression that describes something narrow and local. For non -Dutch speakers the title evokes a powerful religious connotation. So strong that it is at odds with the refuge that is art. Thus, the title has evolved into ‘Under the Tower’ in Arabic and English. A tower, in turn, takes on very different layers of content.</p>
                        <br/>
                        <p>The online conversations are integral to the entire project. Using the title ‘Under the Tower Café’, the artists and the curators meet. These conversations led to the creation of a video work by the artists Alaa Abu Asad and Ignace Cami, provisionally entitled <i>Glossolalia</i>.</p>
                        <br/>
                        <p>Initially, each proposal was reviewed and evaluated by a core team of five members (Griet Dobbels, Essa Grayeb, Jack Persekian, Els Vanden Meersch and Els Vermeersch). Gradually, the team’s role was replaced by Zoom interviews</p>
                        <br/>
                        <p>{ARABIC_TITLE} / ONDER DEKERKTOREN / UNDER THE TOWER began small but has grown into an international network that is fostering artistic collaboration. By combining different strengths, a structure is created that is supported by various people and organisations who have completed, or are in the process of completing, a sustainable trajectory in the art world.</p>
                        <br/>
                        <p>{ARABIC_TITLE} / ONDER DEKERKTOREN / UNDER THE TOWER shows that the pandemic doesn’t need to bring the art world to a standstill. On the contrary, the power of artistic collaboration is a source of energy and new possibilities. The ambition is to demonstrate that it’s high time we looked for new models and, once found, to actually give them shape. The slow and organic growth of {ARABIC_TITLE} / ONDER DEKERKTOREN / UNDER THE TOWER will ensure that connections between artists, curators and exhibition spaces become established and sustainable.</p>
                    </div>
                </div>
            </AbstractCard>
        )
    }
}

export default Process;
