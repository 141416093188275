import React from "react";
import ExhibitionEntry from "../../components/HomePage/ExhibitionEntry";

class TerVestenHomePage extends React.Component {

    constructor(props) {
        super(props);
        this.id = "09";
        this.title = "CC Ter Vesten"
        this.url = "tervesten";
        this.text = "Beveren: ELS VANDEN MEERSCH - JACK PERSEKIAN: 19/07/2021 - 27/03/2022";
    }

    render() {
        return (
            <ExhibitionEntry id={this.id} title={this.title} text={this.text} url={this.url}/>
        );
    }
}

export default TerVestenHomePage;
