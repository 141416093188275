import React from "react";
import {ARABIC_TITLE} from "../../Constants";

class ExhibitionTitleBarOld extends React.Component {

    constructor(props) {
        super(props);
        this.markerDTO = this.props.markerDTO;
    }

    render() {
        return (
            <div>
                <p className="white-text left-align">({this.markerDTO.getID()})</p>
                <p className="white-text left-align">Onder De Kerktoren</p>
                <p className="white-text left-align">Under The Tower</p>
                <p className="white-text left-align">{ARABIC_TITLE}</p>
                <br/>
            </div>
        )
    }
}

export default ExhibitionTitleBarOld;