import React from "react";
import {Marker, Popup} from "react-leaflet";
import {Link} from "react-router-dom";
import {ARABIC_TITLE} from "../../Constants";

// Props:
// id
// title
// url
// externalUrl
// artists
// dates

// lat
// long

// (extraInfo)

class AbstractMarker extends React.Component {

    makeArtistsString = () => {
        return(this.props.artists.join(" - "));
    }

    render() {
        return (
            <Marker position={[this.props.lat, this.props.long]}>
                <Popup>
                    <p className="marker-title">{ARABIC_TITLE}<br/>({this.props.id}) Onder De Kerktoren / Under the Tower</p>
                    <a href={this.props.externalUrl} rel="noopener noreferrer" target="_blank"><p className="bold">{this.props.title}</p></a>
                    <p>{this.props.dates}</p>
                    {this.props.extraInfo}
                    <p>{this.makeArtistsString()}</p>
                    <Link to={"/" + this.props.url}>more info</Link>
                </Popup>
            </Marker>
        )
    }
}

export default AbstractMarker;