import React from "react";
import AbstractExhibitionCard from "../../components/Exhibition/AbstractExhibitionCard";
import SpecificExhibition from "../../components/Exhibition/SpecificExhibition";
import TitleParagraph from "../../components/Exhibition/Paragraphs/TitleParagraph";
import DatesParagraph from "../../components/Exhibition/Paragraphs/DatesParagraph";
import ArtworkWithImage from "../../components/Exhibition/ArtworkWithImage";

class BlueprintDescription extends React.Component {

    constructor(props) {
        super(props);
        this.id = "10";
        this.title = "Blueprint*"
        this.url = "blueprint";
        this.dates = "23 October 2021 - 19:00";
        this.artworks = [
            <ArtworkWithImage number="0" url={this.url} title="Invitation Card"/>
        ];
    }

    render() {
        return (
            <AbstractExhibitionCard id={this.id} className="center">
                <SpecificExhibition artworks={this.artworks}>
                    <TitleParagraph externalUrl={this.externalUrl} title={this.title}/>
                    <br/>
                    <DatesParagraph dates={this.dates}/>
                    <br/>
                </SpecificExhibition>
                <br/>
                <br/>
                <br/>
            </AbstractExhibitionCard>
        )
    }
}

export default BlueprintDescription;