import React from "react";
import AbstractExhibitionCard from "../../components/Exhibition/AbstractExhibitionCard";
import SpecificExhibition from "../../components/Exhibition/SpecificExhibition";
import TitleParagraph from "../../components/Exhibition/Paragraphs/TitleParagraph";
import ArtistsParagraph from "../../components/Exhibition/Paragraphs/ArtistsParagraph";
import DatesParagraph from "../../components/Exhibition/Paragraphs/DatesParagraph";
import ArtworkWithImage from "../../components/Exhibition/ArtworkWithImage";

class QuartairDescription extends React.Component {

    constructor(props) {
        super(props);
        this.id = "08";
        this.title = "Quartair & Arab Film Festival"
        this.url = "quartair_and_arab_film_festival";
        this.externalUrl = "https://www.quartair.nl/under-the-church-tower-hard-times/";
        this.artists = ["ILONA SENGHORE", "PAUL DONKER-DUYVIS", "ROSH ABDELFATAH & RODI KHALIL"];
        this.dates = "02/10/2021 - 17/10/2021";
        this.artworks = [
            <ArtworkWithImage number="0" url={this.url} title="The same breath for the same danger">
                <a href="https://www.ilonasenghore.nl" rel="noopener noreferrer" target="_blank">
                    <p className="artist-name">{this.artists[0]}</p>
                </a>
                <p className="bold">The same breath for the same danger</p>
                <p>2020, crayon on cotton paper, 29,7 x 20,6 cm</p>
                <br/>
                <p className="info">“I am getting a cold and think I am infected. On the day that the Netherlands locks
                    down, the norovirus reigns in my department. In the event of an outbreak, the nursing staff
                    continues to work with the appropriate measures. Other disciplines do not come to the ward in order
                    to limit further spread in the nursing home. I belong to the group of well-being counselors and work
                    in a ward with 32 residents with dementia, half of whom are relatively young. Fortunately, after a
                    week, the norovirus is gone. Unfortunately, the Coronavirus is spreading further across the
                    Netherlands and is breaking out in nursing homes as well.<br/>It is very quiet on the street. I
                    cycle through the quiet streets sobbing to work because I feel strongly that I am a source of
                    contamination and the last thing I want is to infect the residents. The use of face masks will soon
                    be made mandatory. Visitors are no longer allowed. Family and friends are not allowed to visit. That
                    has a huge impact on everyone. Emotional dramas take place. A family secretly rows over the ditch
                    with an inflatable boat to be able to be with their mother. Face-time calling is introduced. I take
                    hundreds of pictures to show the family that they are doing well. Convinced that being outside is
                    the best remedy, I walk for hours with the residents through the paradise garden, which seems more
                    beautiful than ever. The sky is clear blue, there is no noise, no planes.”<br/><br/>Ilona Senghore
                    graduated in 1989 as Monumental Designer at the Royal Academy of Art in The Hague. In addition to
                    being active as an artist, she teaches painting and has been working since 1995 as a well-being
                    counselor in a nursing home. Ilona makes symbolic drawings, paintings and socially critical
                    ceramics.<br/>She draws associatively to understand everyday life, which puzzles her often. From
                    random lines and shapes, a story unfolds that becomes known during the drawing, or even the day
                    after.<br/>Thus the trace appears in her work, the symbol of time. The train comes roaring
                    unstoppably. The trumpet with its bell is the attention that is demanded. The animals can be
                    themselves, but more often symbolize human actions. The water and the sea is the subconscious, a
                    movement and the source from which it all begins.</p>
                <br/>
            </ArtworkWithImage>,
            <ArtworkWithImage number="1" url={this.url} title="Corrona Cancer Comfort">
                <a href="https://pddstudio.wordpress.com" rel="noopener noreferrer" target="_blank">
                    <p className="artist-name">{this.artists[1]}</p>
                </a>
                <p className="bold">Corrona Cancer Comfort</p>
                <p>2021, Print on aluminium, dibond acrylic, 120 x 80 cm</p>
                <br/>
                <p className="info">The Corona outbreak was only a few months old when metastatic tumors were discovered
                    in Paul Donker-Duyvis. Immediately three Muses took care of him and became Angels, Caregivers and
                    Comforters.<br/>Donker-Duyvis decided to use the illness as a vehicle for a staggering, undisguised
                    and moving series of self-portraits, which portray the comforting and warm contact with the three
                    women. In the exhibition, all three of them have their say with a short statement.<br/>The
                    understanding treating physicians quickly turned to life-prolonging treatments: radiation, hormone
                    injection and chemotherapy, but all physical treatments. Psychological help was also offered, but
                    much more important than words were the indispensable touches, which produced an immediate
                    comforting and magical effect.<br/>The touch, the skin, the direct contact, the production of
                    Oxytocin (cuddle hormone) is a neglected part of medical and terminal care in the eyes of the
                    photographer, who is seriously ill. The moving series of straight black and white self-images Corona
                    Cancer Comfort 2021 is a plea for the return of the loving healing touch, which was missed by many
                    during the Corona epidemic.<br/><br/>Paul Donker-Duyvis Lives and works in Amsterdam, the
                    Netherlands. As Dutch artist he strongly relates himself to a non-western concept of Art. Spiritual
                    elements from old ceremonies and rituals in western and other cultures in Africa, Asia and America
                    form an important background and source of inspiration. His performances, Installations and photo
                    work are often spontaneous improvisations. Especially actions in the public domain.</p>
            </ArtworkWithImage>,
            <ArtworkWithImage number="2" url={this.url} title="Here and There Lockdown">
                <p className="artist-name">
                    <a href="https://iffr.com/nl/personen/rosh-abdelfatah" rel="noopener noreferrer" target="_blank">ROSH
                        ABDELFATAH</a> & <a href="https://www.rodi-khalil.de" rel="noopener noreferrer" target="_blank">RODI
                    KHALIL</a>
                </p>
                <p className="bold">Here and There Lockdown</p>
                <p>2021, multimedia installation, 2 x 2 x 2 m</p>
                <br/>
                <p className="info">‘Here and There Lockdown’ is an inner journey, both surreal and realistic, into the
                    world of Rosh Abdelfatah and Rodi Khalil.<br/><br/>Rosh Abdelfatah is a Kurdish/Syrian artist who was
                    “locked up” in Rotterdam during the Covid-19 pandemic. Despite this inconvenience, he went on a
                    virtual trip to Syria, where he visited friends and family online. The multimedia installation,
                    which he made together with the German-Syrian artist Rodi Khalil, consists of paintings, photographs
                    and a video work. It gives a picture of what the lockdown means for his family and friends in Syria.<br/><br/>At
                    the Arab Film Festival Evening on 09 October 2021 Rosh Abdelfatah talks about the Arabic Cinema in
                    Corona times: The lockdown situation has ensured that many initiatives arose in the various Arab
                    coutries. In the online field, vlogs, sketches and series emerged full of seriousness, doubt, a
                    laugh and a tear. In Jordan, young filmmakers were encouraged to make short films. In addition the
                    pandemic, Lebanon also has to deal with the aftermath of the fire explosion. Perhaps the urgency was
                    even greater here to give young makers more space to express themselves through various projects.
                    With the help of stories, photos and film fragments, Abdelfatah gives us a glimpse into the Arab
                    world.<br/><br/>Rosh Abdelfatah is Kurdish/Syrian artist, born in Amouda and currently living in
                    Rotterdam. He finished art school at the St. Joost Academy in Breda in 2006. At present moment of
                    time he is art director at the Arab Film Festival Rotterdam, making freelance productions for
                    different Arabic TV stations and working as an autonomous artist.<br/><br/>Rodi Khalil was born in Tal
                    Hedat, Syria and currently living in Bremen, Germany. He studied art and design at the university of
                    Damascus. He worked as an Stage Designer at the Theatre of Damaskus, made decoration of different TV
                    films, broadcasted in Syria and exposed his paintings and sculptures at several places: London,
                    Rotterdam, Beirut, Turkey, Spain, New York, Sweden and Germany. In Germany he was involved in a
                    movie decoration and became a member of the National Association of Artists (BBK Bremen) and the
                    International Association of Art (IGBK, IAA, AIAP).<br/><br/>“My images are dealing with the beauties of
                    our culture, especially our myths. But they also show suffering, hunger and repression. That‘s a
                    part of it: I come from a land of myths and repression. For me these myths are building a connection
                    to the present. They offer topics which are fascinating for me and which are seen as ageless by me.
                    I want to build a bridge from past to present for all those people, who are open or can be
                    interested for that. My images are showing elements of manifold cultures: Those of the Assyric,
                    Meder, Marer, which I am a child of. I want to transfuse their rich symbolic and spirit to the
                    present. This impulse is forming my paintings. The richness of these traditions is a heavy part of
                    my thinking. Here I want to mediate - not at last through expressing peace and love.“ Rodi Khalil,
                    2021</p>
            </ArtworkWithImage>
        ];
    }

    render() {
        return (
            <AbstractExhibitionCard id={this.id}>
                <SpecificExhibition artworks={this.artworks}>
                    <TitleParagraph externalUrl={this.externalUrl} title={this.title}/>
                    <br/>
                    <ArtistsParagraph artists={this.artists}/>
                    <br/>
                    <DatesParagraph dates={this.dates}/>
                    <br/>
                </SpecificExhibition>
                <br/>
                <br/>
                <br/>
                <p>
                    <a href="https://www.quartair.nl/under-the-church-tower-hard-times/">
                        https://www.quartair.nl/under-the-church-tower-hard-times/
                    </a>
                </p>
            </AbstractExhibitionCard>
        )
    }
}

export default QuartairDescription;