import React from "react";
import {MARKER_DTO_LIST} from "../../data/MarkerData";
import ExhibitionEntry from "./ExhibitionEntry";

class ExhibitionsListOld extends React.Component {

    generateExhibitions = () => {
        const exhibitionFilter = this.props.exhibitionStatus;
        let counter = 0;
        return MARKER_DTO_LIST.map(dto => {
            dto.setID(counter);
            counter++;
            if (dto.exhibitionStatus === exhibitionFilter) {
                return <ExhibitionEntry id={dto.id} title={dto.title} text={dto.homepage} url={dto.url}
                                        key={"homepageExhibition" + dto.getID() + dto.url}/>
            }
            return undefined
        });
    }

    render() {
        return (
            this.generateExhibitions()
        )
    }
}

export default ExhibitionsListOld;
