import React from "react";
import {ARABIC_TITLE} from "../../Constants";

class ExhibitionTitleBar extends React.Component {

    render() {
        return (
            <div>
                <p className="white-text left-align">({this.props.id})</p>
                <p className="white-text left-align">Onder De Kerktoren</p>
                <p className="white-text left-align">Under The Tower</p>
                <p className="white-text left-align">{ARABIC_TITLE}</p>
                <br/>
            </div>
        )
    }
}

export default ExhibitionTitleBar;