import React from "react";
import M from "materialize-css";
import VideoPlayer from "../VideoPlayer";

class SpecificArtwork extends React.Component {

    constructor(props) {
        super(props);
        this.markerDTO = this.props.markerDTO;
        this.number = this.props.number
        this.descriptionDTO = this.markerDTO.description[this.number];
        if (this.descriptionDTO.isVideo()) {
            this.media = <VideoPlayer
                url={this.descriptionDTO.videoUrl}/>
        } else {
            try {
                this.imgUrl = require("../../images/" + this.markerDTO.url + "/" + this.number + ".jpg");
            } catch {
                try {
                    this.imgUrl = require("../../images/" + this.markerDTO.url + "/" + this.number + ".gif");
                }
                catch (e) {
                    console.log("Could not load Artwork\n" + e);
                }
            }
            this.media = <img className="materialboxed responsive-img"
                              src={(this.imgUrl)}
                              alt={this.markerDTO.title}/>
        }

    }

    componentDidMount() {
        document.addEventListener('DOMContentLoaded', function () {
            const elems = document.querySelectorAll('.materialboxed');
            M.Materialbox.init(elems, {});
        });
    }

    render() {
        return (
            <div className="left-align">
                {this.media}
                <a href={this.descriptionDTO.artistUrl} rel="noopener noreferrer" target="_blank">
                    <p className="artist-name">{this.markerDTO.artists[this.number]}</p>
                </a>
                {this.descriptionDTO.title}
                {this.descriptionDTO.metadata}
                <br/>
                {this.descriptionDTO.info}
                <br/>
                <a href={this.descriptionDTO.artworkUrl} rel="noopener noreferrer" target="_blank">{this.descriptionDTO.artworkUrl}</a>
            </div>
        )
    }
}

export default SpecificArtwork;
