import React from "react";
import M from "materialize-css"
import NavBarSide from "./NavBarSide";
import NavBarTop from "./NavBarTop";
import {Link} from "react-router-dom";
import {MENU_ITEMS} from "../../Constants";

class NavBar extends React.Component {

    constructor(props) {
        super(props);
        this.data = MENU_ITEMS;
        this.state = {selected: undefined};
    }

    componentDidMount() {
        M.AutoInit();
        const elems = document.querySelectorAll('.sidenav');
        M.Sidenav.init(elems, {});
    }

    generateNavBarItems = () => {
        return this.data.map(
            (item) =>
                <li key={item}
                    className={(this.props.selected === item ? "darkblue lighten-3 active " : "") + "sidenav-close"}>
                    <Link to={"/" + item.replace(/\s/g, '')}>{item.charAt(0).toUpperCase() + item.slice(1)}</Link></li>
        )
    };

    render() {
        this.navBarItems = this.generateNavBarItems();
        return (
            <div>
                <NavBarTop navBarItems={this.navBarItems}/>
                <NavBarSide navBarItems={this.navBarItems}/>
            </div>
        )
    }
}

export default NavBar;
