import React from "react";
import AbstractMarker from "../../components/MapCard/AbstractMarker";

class DarJacirMarker extends React.Component {

    constructor(props) {
        super(props);
        this.id = "06";
        this.title = "Dar Jacir"
        this.url = "artist_book_under_tower";
        this.externalUrl = "https://darjacir.com";
        this.artists = [];
        this.dates = "All Artists: 01/10/2022";
        this.lat = "31.705564";
        this.long = "35.202485";
    }

    render() {
        return (
            <AbstractMarker id={this.id} title={this.title} url={this.url} externalUrl={this.externalUrl}
                            artists={this.artists} dates={this.dates} lat={this.lat} long={this.long}/>
        );
    }
}

export default DarJacirMarker;
