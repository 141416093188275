import React from "react";

class DescriptionDTO {
    constructor(title, metadata, info, artistUrl, artworkUrl, videoUrl = [], extraInfo = <div/>) {
        this.title = title;
        this.metadata = metadata;
        this.info = info;
        this.artistUrl = artistUrl;
        this.artworkUrl = artworkUrl;
        this.videoUrl = videoUrl;
        this.extraInfo = extraInfo;
    }

    isVideo = () => {
        return this.videoUrl.length !== 0;
    }
}

export default DescriptionDTO;
