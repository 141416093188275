import React from 'react';
import {Link} from "react-router-dom";

class NavBarSide extends React.Component {
    render() {
        return (
            <ul className="sidenav" id="mobile-demo">
                <li className={(this.props.selected === "" ? "active " : "") + "sidenav-close"}>
                    <Link to="" className="nav-content padded-left">Home</Link>
                </li>
                <li>
                    <div className="divider"/>
                </li>
                {this.props.navBarItems}
            </ul>
        )
    }
}

export default NavBarSide;