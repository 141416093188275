import React from "react";
import {ARABIC_TITLE} from "../../Constants";
import {Link} from "react-router-dom";

class ArtistBookUnderTowerHomePage extends React.Component {

    constructor(props) {
        super(props);
        this.id = "11";
        this.url = "artist_book_under_tower";
        this.text = `Launch ARTIST’S BOOK Under the Tower / Onder de Kerktoren / ${ARABIC_TITLE}\n\n
`;
    }

    render() {
        return (
            <tr>
                <td>
                    <p>
                        {`(${this.id}) ${this.text}`} <Link to={this.url}> more info</Link>
                        <br/>
                        WARP, Sint-Niklaas : 20/08/2022
                        <br/>
                        Dar Jacir, Bethlehem :  01/10/2022
                    </p>
                </td>
            </tr>
        );
    }
}

export default ArtistBookUnderTowerHomePage;
