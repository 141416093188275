import React from "react";
import AbstractMarker from "../../components/MapCard/AbstractMarker";

class TerVestenMarker extends React.Component {

    constructor(props) {
        super(props);
        this.id = "09";
        this.title = "CC Ter Vesten"
        this.url = "tervesten";
        this.externalUrl = "https://www.tervesten.be/tentoonstellingen/onder-de-kerktoren";
        this.artists = ["ELS VANDEN MEERSCH", "JACK PERSEKIAN"];
        this.dates = "19/07/2021 - 27/03/2022";
        this.lat = "51.213184";
        this.long = "4.262088";
    }

    render() {
        return (
            <AbstractMarker id={this.id} title={this.title} url={this.url} externalUrl={this.externalUrl}
                            artists={this.artists} dates={this.dates} lat={this.lat} long={this.long}/>
        );
    }
}

export default TerVestenMarker;
