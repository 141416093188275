import React from "react";
import AbstractCard from "./AbstractCard/AbstractCard";
import CardTitle from "./AbstractCard/CardTitle";
import {EMAIL_ADDRESS} from "../Constants";

class Contact extends React.Component {

    render() {
        return (
            <AbstractCard>
                <div className="card-content">
                    <CardTitle>Contact</CardTitle>
                    <div>
                        <a href={"mailto:" + EMAIL_ADDRESS}>{EMAIL_ADDRESS}</a>
                    </div>
                </div>
            </AbstractCard>
        )
    }
}

export default Contact;