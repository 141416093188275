import React from "react";
import AbstractExhibitionCard from "../../components/Exhibition/AbstractExhibitionCard";
import SpecificExhibition from "../../components/Exhibition/SpecificExhibition";
import TitleParagraph from "../../components/Exhibition/Paragraphs/TitleParagraph";
import ArtistsParagraph from "../../components/Exhibition/Paragraphs/ArtistsParagraph";
import DatesParagraph from "../../components/Exhibition/Paragraphs/DatesParagraph";
import ArtworkWithImage from "../../components/Exhibition/ArtworkWithImage";

class TerVestenDescription extends React.Component {

    constructor(props) {
        super(props);
        this.id = "09";
        this.title = "CC Ter Vesten"
        this.url = "tervesten";
        this.externalUrl = "https://www.tervesten.be/tentoonstellingen/onder-de-kerktoren";
        this.artists = ["ELS VANDEN MEERSCH", "JACK PERSEKIAN"];
        this.dates = "19/07/2021 - 27/03/2022";
        this.artworks = [
            <ArtworkWithImage number="0" url={this.url} title="The same breath for the same danger">
                <a href="https://elsvandenmeersch.be/" rel="noopener noreferrer" target="_blank">
                    <p className="artist-name">{this.artists[0]}</p>
                </a>
                <p className="bold">The same breath for the same danger</p>
                <p>2020, crayon on cotton paper, 29,7 x 20,6 cm</p>
                <br/>
                <p className="info">
                    "Jerusalem Stone" is the most common limestone traditionally used in Jerusalem's architecture. The stone is
                    nicknamed ‘gold of Jerusalem’ because of its yellow glow during the day. The photo series was shot in the
                    early morning between 4 and 7 a.m., during the well-known 'l'Heure Bleue' or perhaps the 'calm before the
                    storm'. Every street of the city is mapped just before the noisy start of activities. The gaps between
                    stones are used as repositories, to stones one complains, stones are built with, stones are worshipped and
                    oiled, stones are thrown, stones are collected, stones are studied, stones are buried under… The time span
                    chosen to photograph is a moment when it’s dead quiet and stones seem to crack. Praying stops, shops are
                    closed, soldiers are not visibly present. The depiction of the city map in 160 photos shows the ancient
                    building blocks as inert during the cold hour just before they become instrumental again. It is the point at
                    which the foreground again becomes the backdrop of the tumultuous scenes.
                </p>
                <br/>
            </ArtworkWithImage>,
            <ArtworkWithImage number="1" url={this.url} title="Corrona Cancer Comfort">
                <a href="https://pddstudio.wordpress.com" rel="noopener noreferrer" target="_blank">
                    <p className="artist-name">{this.artists[1]}</p>
                </a>
                <p className="bold">Past Tense</p>
                <br/>
                <p className="info">
                    <br/>
                    Jerusalem’s landscape, as we know it today, is merely a surface layer, a slice in a long tumultuous history
                    that has witnessed a succession of takeovers by people and civilizations.
                    <br/>
                    About 100 years ago the American Colony Photography Department took it upon themselves to photodocument the
                    city in detail from all corners. The American Colony photographic collection is remarkable for the
                    methodical and high quality photographic documentation, and for the resourcefulness of the chief
                    photographer Hol Lars (Lewis) Larsson and later the foresight of G. Eric Matson.
                    <br/>
                    By superimposing an additional layer – a photograph taken today of the same location, we are able to move
                    between two distinct times. Several photographic pairs that I composed reveal the sheer optical
                    discrepancies between the eastern part of the city and the western part.
                    <br/>
                    This seemingly blessed Jerusalem is chockfull of contradictions. Yet, no leader, political body or group of
                    people, has so far been able to bring the various communities living here closer together.
                    <br/>
                    This body of work is an attempt to encourage people, in general, and the people of Jerusalem, in particular,
                    to reassess their relation to this city, reexamine it carefully, indulge in its details and love it – not
                    for what it was and what it signifies, but for what it can be.
                    <br/>
                    If “God is in the details,” as Mies van der Rohe is credited for stating so eloquently, then perhaps these
                    photographs can allow us to see the sacred in the city once more.
                    <br/>
                    <br/>
                    Jack Persekian
                </p>
            </ArtworkWithImage>,
        ];
    }

    render() {
        return (
            <AbstractExhibitionCard id={this.id}>
                <SpecificExhibition artworks={this.artworks}>
                    <TitleParagraph externalUrl={this.externalUrl} title={this.title}/>
                    <br/>
                    <ArtistsParagraph artists={this.artists}/>
                    <br/>
                    <DatesParagraph dates={this.dates}/>
                    <br/>
                </SpecificExhibition>
            </AbstractExhibitionCard>
        )
    }
}

export default TerVestenDescription;
