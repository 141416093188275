import React from "react";
import AbstractExhibitionCard from "../../components/Exhibition/AbstractExhibitionCard";

class DarJacirDescription extends React.Component {

    constructor(props) {
        super(props);
        this.id = "06";
        this.title = "Dar Jacir"
        this.url = "artist_book_under_tower";
        this.externalUrl = "https://darjacir.com"
        this.artists = [];
        this.dates = "";
        this.artworks = [
        ];
    }

    render() {
        return (
            <AbstractExhibitionCard id={this.id}>
                <div>
                    <a href={this.externalUrl} rel="noopener noreferrer" target="_blank"><p
                        className="left-align bold">{this.title}</p></a>
                    <br/>
                    <p className="left-align">{this.dates}</p>
                    <br/>
                    <p className="left-align">Coming soon: dates - artists</p>
                    <br/>
                </div>
            </AbstractExhibitionCard>
        )
    }
}

export default DarJacirDescription;
