import React from 'react';
import {Link} from "react-router-dom";
import {ARABIC_TITLE} from "../../Constants";

class NavBarTop extends React.Component {

    constructor(props) {
        super(props);
        // I'll be honest, this isn't the most clean way to this.
        this.arabicTitle = ARABIC_TITLE;
        this.logoRight =
            <div className="logo-right">{this.arabicTitle}<br/>Onder de Kerktoren / Under the Tower</div>;
        this.logoLeft =
            <p className="logo-left">{this.arabicTitle}<br/> Onder de Kerktoren / Under the Tower</p>;
    }


    render() {
        return (
            <div className="navbar-fixed">
                <nav className="darkblue">
                    <div className="nav-wrapper">
                        <Link to="" className="hide-on-med-and-down nav-content padded-left">{this.logoLeft}</Link>
                        <Link to=""
                              className="hide-on-large-only nav-content right padded-right right-align">{this.logoRight}</Link>
                        <a onClick={(e) => e.preventDefault()} data-target="mobile-demo" className="sidenav-trigger"
                           href={"#!"}>
                            <i className="hide-on-small-and-up material-icons">
                                list
                            </i>
                        </a>
                        <ul className="right hide-on-med-and-down">
                            {this.props.navBarItems}
                        </ul>
                    </div>
                </nav>
            </div>
        )
    }
}

export default NavBarTop;