import React from "react";
import {Map, TileLayer} from 'react-leaflet';
import AbstractMarker from "./AbstractMarker";
import {MARKER_DTO_LIST} from "../../data/MarkerData";
import QuartairMarker from "../../views/quartair/QuartairMarker";
import ArabFilmMarker from "../../views/quartair/ArabFilmMarker";
import BlueprintMarker from "../../views/blueprint/BlueprintMarker";
import ArtistBookUnderTowerMarker from "../../views/artist_book_under_tower/ArtistBookUnderTowerMarker";
import TerVestenMarker from "../../views/tervesten/TerVestenMarker";
import DarJacirMarker from "../../views/darjacir/DarJacirMarker";

class EventMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = { // The default position of the map
            current_lat: 42.357262,
            current_long: 12.898741,
            zoom: 2
        };
    }

    createMarkers = () => {
        let markers = [];
        for (const [i, markerDTO] of MARKER_DTO_LIST.entries()) {
            markers.push(this.createMarker(i, markerDTO));
        }
        return markers;
    }

    createMarker = (key, markerDTO) => {
        markerDTO.setID(key);
        return <AbstractMarker id={markerDTO.id} title={markerDTO.title} url={markerDTO.url}
                               externalUrl={markerDTO.externalUrl} artists={markerDTO.artists} dates={markerDTO.dates}
                               lat={markerDTO.lat} long={markerDTO.long} key={key} extraInfo={markerDTO.extraInfo}/>

    }

    render() {
        return (
            <Map center={[this.state.current_lat, this.state.current_long]} zoom={this.state.zoom}
                 id="mapbox/streets-v11">
                <TileLayer
                    attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                    url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
                />
                {this.createMarkers()};
                <DarJacirMarker/>
                <ArabFilmMarker/>
                <QuartairMarker/>
                <TerVestenMarker/>
                <BlueprintMarker/>
                <ArtistBookUnderTowerMarker/>
            </Map>
        );
    }
}

export default EventMap;
