import React from "react";
import {Link} from "react-router-dom";

class ExhibitionEntry extends React.Component {
// Props:
// id
// title
// text
// url

    generateRow = () => {
        let text = [];
        text.push("(" + this.props.id + ")");
        text.push(this.props.title);
        text = text.join(" ") + ", ";
        text += this.props.text;
        return text;
    }

    render() {
        let row
        if (this.props.text !== undefined) {
            row = <td>{this.generateRow()}<Link to={this.props.url}> more info</Link></td>
        }
        return (
            <tr>
                {row}
            </tr>
        )
    }
}

export default ExhibitionEntry;