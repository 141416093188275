const MENU_ITEMS = [
    "about",
    "process",
    "world map",
    "contact",
];

const MAP_ID = "mapCard";
const COOKIE_MODAL_ID = "cookieModal";
const ARABIC_TITLE = "تحت البرج";
const EMAIL_ADDRESS = "underthechurchtower@gmail.com";

export {MENU_ITEMS, MAP_ID, COOKIE_MODAL_ID, ARABIC_TITLE, EMAIL_ADDRESS};