import React from "react";
import AbstractCard from "../AbstractCard/AbstractCard";
import {MAP_ID} from "../../Constants";
import ExhibitionTitleBarOld from "./ExhibitionTitleBarOld";
import SpecificExhibitionOld from "./SpecificExhibitionOld";
import IncompleteExhibition from "./IncompleteExhibition";

class AbstractExhibitionCardOld extends React.Component {

    constructor(props) {
        super(props);
        this.markerDTO = this.props.markerDTO;
        this.descriptionDTO = this.markerDTO.description;
    }

    loadAndScroll = () => {
        setTimeout(this.scrollDown, 100);
    }

    scrollDown = () => {
        // Subtract 80 at top, because of navbar
        const mapCard = document.getElementById(MAP_ID);
        window.scrollBy({
            top: mapCard.getBoundingClientRect().bottom - 80,
            left: 0,
            behavior: "smooth"
        });
    }

    componentDidMount() {
        this.loadAndScroll();
    }

    render() {
        let exhibition;
        if (this.markerDTO.isComplete) {
            exhibition = <SpecificExhibitionOld markerDTO={this.markerDTO}/>
        } else {
            exhibition = <IncompleteExhibition markerDTO={this.markerDTO}/>
        }
        return (
            <AbstractCard>
                <div className="card-content exhibition-card">
                    <ExhibitionTitleBarOld markerDTO={this.markerDTO}/>
                    <br/>
                    {exhibition}
                </div>
            </AbstractCard>
        );
    }
}

export default AbstractExhibitionCardOld;
