import React from "react";
import M from "materialize-css";

class ArtworkWithImage extends React.Component {
// Verwacht volgende props:
// url
// number
// title
    constructor(props) {
        super(props);
        try {
            this.imgUrl = require("../../images/" + this.props.url + "/" + this.props.number + ".jpg");
        } catch (e) {
            try {
                this.imgUrl = require("../../images/" + this.props.url + "/" + this.props.number + ".gif");
            } catch (e) {
                try {
                    this.imgUrl = require("../../images/" + this.props.url + "/" + this.props.number + ".png");
                } catch (e) {
                    console.warn("Could not load Artwork\n" + e);
                }
            }
        }
        this.media = <img className="materialboxed responsive-img"
                          src={(this.imgUrl)}
                          alt={this.props.title}/>
    }

    componentDidMount() {
        document.addEventListener('DOMContentLoaded', function () {
            const elems = document.querySelectorAll('.materialboxed');
            M.Materialbox.init(elems, {});
        });
    }

    render() {
        return (
            <div className="left-align">
                {this.media}
                {this.props.children}
            </div>
        )
    }
}

export default ArtworkWithImage;
