import React from "react";

class Error404 extends React.Component {
    render() {
        return (
            <div>
                <table>
                    <tbody>
                    <tr>
                        <td><h1 className="right-align">404.</h1></td>
                        <td className="vertical-bottom-error"><h4 className="left-align">We couldn't find that page.</h4></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default Error404