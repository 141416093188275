import React from "react";
import {Route, Switch} from "react-router-dom";
import Error404 from "../Error404";

import About from "../About";
import {MARKER_DTO_LIST} from "../../data/MarkerData";
import Process from "../Process";
import AbstractExhibitionCardOld from "../Exhibition/AbstractExhibitionCardOld";
import HomePageCard from "../HomePage/HomePageCard";
import Contact from "../Contact";
import QuartairDescription from "../../views/quartair/QuartairDescription";
import BlueprintDescription from "../../views/blueprint/BlueprintDescription";
import ArtistBookUnderTowerDescription from "../../views/artist_book_under_tower/ArtistBookUnderTowerDescription";
import TerVestenDescription from "../../views/tervesten/TerVestenDescription";
import DarJacirDescription from "../../views/darjacir/DarJacirDescription";

class MainSwitch extends React.Component {

    constructor(props) {
        super(props);
        this.routes = this.makeRoutes();
    }

    makeRoutes = () => {
        const routes = [];
        for (const [i, markerDTO] of MARKER_DTO_LIST.entries()) {
            routes.push(<Route exact path={"/" + markerDTO.url} key={i}>
                <AbstractExhibitionCardOld markerDTO={markerDTO}/>
            </Route>)
        }
        return routes
    }

    render() {
        return (
            <Switch>
                <Route exact path="/about">
                    <About/>
                </Route>
                <Route exact path="/process">
                    <Process/>
                </Route>
                <Route exact path="/worldmap"/> {/* Don't show TitleCard on /worldmap, the map will show itself */}
                <Route exact path="/contact">
                    <Contact/>
                </Route>
                <Route exact path="/">
                    <HomePageCard/>
                </Route>
                {this.routes}
                <Route exact path="/quartair_and_arab_film_festival">
                    <QuartairDescription/>
                </Route>
                <Route exact path="/blueprint">
                    <BlueprintDescription/>
                </Route>
                <Route exact path="/artist_book_under_tower">
                    <ArtistBookUnderTowerDescription/>
                </Route>
                <Route exact path="/tervesten">
                    <TerVestenDescription/>
                </Route>
                <Route exact path="/darjacir">
                    <DarJacirDescription/>
                </Route>
                <Route>
                    <Error404/>
                </Route>
            </Switch>
        )
    }
}

export default MainSwitch;
