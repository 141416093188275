import React from "react";

class SpecificExhibition extends React.Component {
// Props:
// artworks = list of <ArtworkWithImage/>

    constructor(props) {
        super(props);
        this.artworks = this.props.artworks;
    }

    makeMobileList = () => {
        const list = [];
        for (const [i, artwork] of this.artworks.entries()) {
            list.push(artwork);
            list.push(<br key={i}/>);
        }
        list.pop();
        return list;
    }

    makeTableRows = () => {
        const artworklist = Array.from(this.artworks);
        const table = [];
        let even = true;
        if (artworklist.length % 2 === 1) {
            even = false;
        }
        while (artworklist.length >= 2) { // A pair of artworks can be generated
            table.push(<tr key={artworklist.length + 90000}>
                <td>{artworklist[0]}</td>
                <td>{artworklist[1]}</td>
            </tr>);
            artworklist.splice(0, 2);
        }
        if (!even) { // generate last artwork
            table.push(<tr key={1 + 90000}>
                <td>{artworklist[0]}</td>
            </tr>);
        }
        return table;
    }

    render() {
        return (
            <div>
                {this.props.children}
                <table className="hide-on-med-and-down">
                    <tbody>
                    {this.makeTableRows()}
                    </tbody>
                </table>
                <div className="hide-on-large-only">
                    {this.makeMobileList()}
                </div>
            </div>
        )
    }
}

export default SpecificExhibition;