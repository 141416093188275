import React from "react";
import ReactPlayer from 'react-player/lazy'

class VideoPlayer extends React.Component {

    constructor(props) {
        super(props);
        this.url = this.props.url;
    }

    render() {
        return (
                <ReactPlayer controlsList="nodownload" onContextMenu={e => e.preventDefault()} id="noDownloadMenu" url={this.url} controls={true} width="100%" height="100%"/>
        )
    }
}

export default VideoPlayer;
