import React from 'react';
import 'materialize-css/dist/css/materialize.min.css'
import {BrowserRouter as Router} from "react-router-dom";
import NavBar from "./components/NavBar/NavBar";
import MainSwitch from "./components/Routes/MainSwitch";
import MapCard from "./components/MapCard/MapCard";
import M from "materialize-css";
import "instant.page";
import CookieModal from "./components/CookieModal";

class App extends React.Component {

    hasCookieSet = () => {
        const name = "cookie_accept=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return true;
            }
        }
        return false;
    }

    componentDidMount() {
        if (! this.hasCookieSet()) {
            document.addEventListener('DOMContentLoaded', function () {
                const elems = document.querySelectorAll('.modal');
                M.Modal.init(elems, {
                    dismissible: false
                });
                const instance = M.Modal.getInstance(elems[0]);
                instance.open();
            });
        }
    }

    render() {
        return (
            <Router>
                <CookieModal/>
                <NavBar/>
                <MapCard/> {/* Because of lifetime issues with the map, just hide it in css if not needed */}
                <MainSwitch/>
            </Router>
        );
    }
}

export default App;
