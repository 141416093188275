import React from "react";

export const GLOSSOLALIA_METADATA = <p className="arabic-text">فيديو مع صوت، 14 دقيقة و 37 ثانية، 2021</p>;
const ALAA_NAME = <b>علاء أبو أسعد</b>;
const IGNACE_NAME = <b>إنياس كامي</b>;
export const GLOSSOLALIA_P1 = <p className="arabic-text">يكشف عمل ثَرثَرة عن تركيباتٍ شاعريّة مكوّنةً قصيدة لمتعة مشاهدة الصور والاستماع للأصوات. تتحوّل ثَرثَرة اللغة المبهمة وصعوبة التواصل والفهم إلى شعرٍ نقيّ لكن جَذِل. يلمّح العمل إلى لقاءات مشروع معرض "تحت البرج" عبر الإنترنت، لكن لا يتكئ عليها بشكلٍ كليّ؛ فثَرثَرة الفيديو هي محادثة بين فنانَين تفتح نافذة على العالم بواسطة أدوات سمعيّة وبصريّة، مقدّمةً رحلة تأمّلٍ [وسكونٍ نفسيّ].</p>;
export const GLOSSOLALIA_P2 = <p className="arabic-text">ثرثرة اللسان شكلٌ من أشكال سلوك الكلام غير المألوف، وتُعرف أيضًا باسم "لغة العيّي" أو "نطق المعتوهين". قد تكون اللغة البصريّة واضحة للبعض، وغامضة جدًّا للآخرين، لكنّها - على كلّ حال - مُلفتة للنّظر؛ وقد تعمّ الضوضاء بسبب فعل الترجمة، لكنّ اللغة، بصريّة كانت أو نصيّة، تثير روابط تاريخيّة وثقافيّة [داخل سياق العمل الفنيّ].</p>;
export const GLOSSOLALIA_P3 = <p className="arabic-text"> {ALAA_NAME} فنّان وباحث ومصوّر من فلسطين، تتركّز أعماله حول قيم ومفاهيم الصورة والترجمة، وعمليّة المشاهدة والقراءة.</p>;
export const GLOSSOLALIA_P4 = <p className="arabic-text">تتجذّر ممارسة {IGNACE_NAME} متعدّدة التخصّصات عميقًا في الفلكلور وتستكشف [مواضيع] الهويّة والتقاليد. متّخذًا ثقافته المحليّة نقطة انطلاق، يعمل إنياس في حقل رواية القصص والنحت والأداء والتركيبات [الفنيّة].</p>;
export const GLOSSOLALIA_P5 = <p className="arabic-text">أُنتج هذا العمل ضمن مشروع معرض تحت البرج / Onder de Kerktoren / Under the Tower، ومستوحى من محادثات عبر الإنترنت مع غريت دوبيلس وإلس فِرميرس وعيسى غريّب وجاك برسكيان وإلس فاندين ميرس ومنال محاميد وستيف فان بيلنغن وميلينا ديسّا وميرنا بامية وشذى صفدي وشروق حرب وريناد شقيرات وجيسي راهمَن وروش عبد الفتّاح وبلانكا دي براوني وسام إيغرمونت وستيفان فان بيسين. </p>;
export const GLOSSOLALIA_P6 = <p className="arabic-text">بدعم من فلاندرس، ستيت أوف آرت</p>;
