import React from "react";
import AbstractExhibitionCard from "../../components/Exhibition/AbstractExhibitionCard";
import SpecificExhibition from "../../components/Exhibition/SpecificExhibition";
import TitleParagraph from "../../components/Exhibition/Paragraphs/TitleParagraph";
import DatesParagraph from "../../components/Exhibition/Paragraphs/DatesParagraph";
import {ARABIC_TITLE, EMAIL_ADDRESS} from "../../Constants";
import {Link} from "react-router-dom";
import VideoPlayer from "../../components/VideoPlayer";

class ArtistBookUnderTowerDescription extends React.Component {

    constructor(props) {
        super(props);
        this.id = "11";
        this.title = "";
        this.url = "artist_book_under_tower";
        this.artworks = [<div className="left-align">
            <VideoPlayer
                url="https://raw.githubusercontent.com/Tram13/webserver-backend/master/api/other/artist_book_video.m3u8"/>
            <a href="https://elsvandenmeersch.be/" rel="noopener noreferrer" target="_blank">
                <p className="artist-name">ARTIST'S BOOK</p>
            </a>
            <p className="bold">Art edition of 100</p>
            <p>2022, Riso printed on Munken Lynx rough (90 g), screen printed cover in cotton gauze, folded 28,5 x 21
                cm, unfolded 28,5 x 300 cm</p>
            <table>
                <tbody>
                <tr>
                    <td>
                        <p className="cursive small">
                            €50
                            <br/>
                            available at the following locations:
                            <br/>
                            <a href="https://www.emergent.be/nl/" rel="noopener noreferrer" target="_blank">EMERGENT, Veurne, B</a>
                            <br/>
                            <a href="https://www.warp-art.be" rel="noopener noreferrer" target="_blank">WARP, Sint-Niklaas, B</a>
                            <br/>
                            <a href="https://www.wiels.org/en/exhibitions" rel="noopener noreferrer" target="_blank">WIELS, bookshop, Brussels, B</a>
                            <br/>
                            <a href="https://almamal.org" rel="noopener noreferrer" target="_blank">Al Ma’Mal Foundation, Old City Jerusalem</a>
                            <br/>
                            <a href="https://educationalbookshop.com" rel="noopener noreferrer" target="_blank">Educational Bookshop, Jerusalem</a>
                        </p>
                    </td>
                    <td>
                        <p className="cursive small">
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            Order via <a href={"mailto:" + EMAIL_ADDRESS + "?subject=Order ARTIST'S BOOK"}>email</a>
                            <br/>
                            Europe, shipping included: €60
                            <br/>
                            Outside Europe: <a href={"mailto:" + EMAIL_ADDRESS + "?subject=Order ARTIST'S BOOK: Outside EU"}>more info</a>
                        </p>
                    </td>
                </tr>
                </tbody>
            </table>
            <br/>
            <p className="info">
                This artist’s book is the conclusion of the art project Under the Tower / Onder de Kerktoren
                / {ARABIC_TITLE}, an artists' initiative in which various artists, curators and art centers have
                collaborated. It is a tangible reflection on the project <Link to="/about">Under the Tower / Onder de
                Kerktoren
                / {ARABIC_TITLE}</Link> and <Link to="/process">the process</Link>.
                <br/>
                <br/>
                The edition consists of two parts; a colored booklet and a leporello sewn into a screen printed
                cotton gauze handmade cover. In the booklet, there are three texts from three authors, translated in
                to three languages.
                <br/>
                The leporello shows the chronological timeline of all participating artists, with an image of their
                artwork that was part in the <Link to="/">exhibitions</Link>.
                <br/>
                <br/>
                Is there a difference between digital presumptions and real-life experiences?
                <br/>
                <br/>
                Under the Tower / Onder de Kerktoren / {ARABIC_TITLE} started life as an open enquiry. What happens
                when we take or select a position, or are even forced into one? What happens when we connect
                different languages, insights and standpoints? Do we transcend the confines of the us/them mindset?
                <br/>
                Under the Tower / Onder de Kerktoren / {ARABIC_TITLE} soon revealed that diversity leads to
                connections but also exposes frictions. For example, the group decided not to make a literal
                translation of the Flemish proverb Onder de kerktoren [under the church tower] (which means the
                small scale, the provincial, or navel-gazing). The church tower has other connotations in different
                cultures. This is an example of how the process becomes part of the content.
                <br/>
                The three authors write about Under the Tower / Onder de Kerktoren / {ARABIC_TITLE} from different
                perspectives. Shuruq Harb (Ramallah, Palestine) is a visual artist, Mahmood Messkoub (The Hague,
                Netherlands) is an academic and Stef van Bellingen (Sint Niklaas, Belgium) is a curator. The texts
                are crucial. They add a different dimension to the artworks, the diverse exhibition venues and the
                video work ثَرثَرة / Glossolalia. In translating to the various languages, we are faced with a
                choice: do we go for the perfect, edited, correct translation or do we let our own voices shine
                through?
                <br/>
                <br/>
                CREDITS
                <br/>
                <br/>
            </p>
            <p className="info bold">Concept</p>
            <p>Griet Dobbels in collaboration with Sophia Attigui, and Topo Copy</p>
            <br/>
            <p className="info bold">Authors</p>
            <p>Shuruq Harb, Mahmood Messkoub, Stef Van Bellingen</p>
            <br/>
            <p className="info bold">Translators</p>
            <p>Alaa Abu Asad, Malak Afouneh, Hilde Pauwels, Helen Simpson, Jenke Van den Akkerveken</p>
            <br/>
            <p className="info bold">Graphic design</p>
            <p>Sophia Attigui</p>
            <br/>
            <p className="info bold">Final Editing</p>
            <p>Griet Dobbels, Els Vermeersch, Alaa Abu Asad</p>
            <br/>
            <p className="info bold">Printing</p>
            <p>Topo Copy, Riso printed on Munken Lynx rough (90 g), screen printed cover in cotton gauze</p>
            <br/>
            <p className="info bold">Edition of 100</p>
            <p>© artworks : all artists and curators, © text : the authors</p>
            <br/>
            <p className="info bold">Book Launch</p>
            <p>The artist’s book is launched in September - October 2022 in Dar Jacir for Art and Research,
                experimental cultural hub in Bethlehem (PS) and in WARP, platform for contemporary art in
                Sint-Niklaas (BE).</p>
            <br/>
            <p className="info bold">With thanks to</p>
            <p>Hilde Borgermans, Benji Boyadgian, Akef Darawsheh, Shayma Nader, Blanka de Bruyne, Marijke De Moor,
                Dirk De Wit, Marjolijn Dijkman, Sam Eggermont, Rabeea Eid, Mohamed Ikoubaan, Emily Jacir, Adele
                Jarrar, Aline Khoury, Lissa Kinnaer, Frank Maes, Lieven Pyfferoen, Jessy Rahman, Tina Sherwell,
                Renad Shqeirat, Stefaan van Biesen, Christl Van Den Broucke, Agnes Van Landuyt</p>
            <br/>
        </div>];
    }

    render() {
        return (<AbstractExhibitionCard id={this.id} className="center">
            <SpecificExhibition artworks={this.artworks}>
                <TitleParagraph externalUrl={this.externalUrl} title={this.title}/>
                <br/>
                <DatesParagraph dates={this.dates}/>
                <br/>
            </SpecificExhibition>
        </AbstractExhibitionCard>)
    }
}

export default ArtistBookUnderTowerDescription;
